import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form } from "antd";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { FORGOT_PASSWORD } from "../../store/user/user.types";
import "./ForgotPassword.css";
class ForgotForm extends React.Component {
  onSubmit = (values) => {
    this.props.onSubmit(values);
  };

  render() {
    const { user } = this.props;
    return (
      <>
        {user.action === FORGOT_PASSWORD ? <Notifications user={user} /> : null}
        <Form onFinish={this.props.handleSubmit(this.onSubmit)}>
          <Field name="email" placeholder="Email" component={createField} />
          <div className="btn-container">
            <Button type="primary" htmlType="submit" className="btn">
              Submit
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "This field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "ForgotForm",
  validate,
})(ForgotForm);
