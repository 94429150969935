import React from "react";
import { Field, reduxForm } from "redux-form";
import { Input, Form, Button } from "antd";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { UPDATE_USER_SUCCESS } from "../../store/user/user.types";

class AccountForm extends React.Component {
  onSubmit = (values) => {
    this.props.onSubmit(values);
  };

 
  render() {
    const { user } = this.props;

    return (
      <>
        {user.action === UPDATE_USER_SUCCESS ? <Notifications user={user} /> : null}
        <Form layout="vertical" className="profile-action-form" onFinish={this.props.handleSubmit(this.onSubmit)}>
          <Form.Item>
            <Field name="fullname" label="Full name" placeholder="Email" component={createField} />
          </Form.Item>
          <Form.Item>
            <Field name="email" label="Email" placeholder="Email" component={createField} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="account-btn">
              SAVE CHANGES
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.fullname) {
    errors.fullname = "This field is Required";
  }
  if (!values.email) {
    errors.email = "This field is Required";
  }

  return errors;
};

export default reduxForm({
  form: "AccountForm",
  validate,
})(AccountForm);
