import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import { addUserToGroup, createUser, verifyUser } from '../../store/coursecreator/coursecreators.actions';
import { CC_CREATE_USER, CC_VERIFY_USER } from '../../store/coursecreator/coursecreators.types';
const CourseRegForm = (props) => {
   const cc_user = useSelector(state => state.cc_user)
    const dispatch = useDispatch()
    const onFinish = (values) => {
      if(values.code){
          dispatch(verifyUser(values.code))
          dispatch(addUserToGroup(cc_user.user.uid))

      }else{
        dispatch(createUser(values))
      }
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
  return (
    <div className="instructor-form-area">
        {(cc_user.action === CC_CREATE_USER ) || (cc_user.action === CC_VERIFY_USER && !cc_user.user.status) ? 
         ( <Form name="coursecreatorRegistration" wrapperCol={{ span: 16, }} initialValues={{ remember: true,
        }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="form-verify-user">
        <Form.Item
          label="Verify User"
          name="code"
          rules={[
            {
              required: true,
              message: 'Please input your code!',
            },
          ]}
        >
        <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 12,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Verify User
          </Button>
        </Form.Item>

      </div>

    </Form>
        ): 
        cc_user.user.verified ? (<div> <Popup username={cc_user.user.username} msg='You are registered Successfully' url={'https://develop.d1j3mx9d0njri.amplifyapp.com/login'}/> </div>) :
        (
    <Form
      name="coursecreatorRegistration" className="course-creator-form-area"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            placeholder: "Password",
            message: 'Please input your Email!',
          },
          {
            type: 'email',
            message: 'Please enter a valid email address!',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
            
          },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            message: 'Password must be at least 8 characters long and contains a special charachter and one number!',
          },
        ]}
      >
        <Input.Password  placeholder="Password" />
      </Form.Item>

      <Form.Item name="remember" valuePropName="unchecked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" className="instructor-form-buttuon">
          Sign Up
        </Button>
      </Form.Item>

    </Form> 
        )}
    </div>
  )
}

export default CourseRegForm