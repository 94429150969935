import React, { useEffect } from "react";
import { Content } from "antd/lib/layout/layout";
import Layout from "../../components/Layout";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Layout>
      <Content>
        <div className="privacy-policy">
          <h6>Privacy policy</h6>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
         
        </div>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;
