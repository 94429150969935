export const LIST = 'LIST';
export const CATEGORY_BASED = 'CATEGORY_BASED';
export const VIEW = 'VIEW';
export const DETAIL = 'DETAIL';
export const ADDTOCART = 'ADDTOCART';
export const PROD_QUANTITY = 'PROD_QUANTITY';
export const SUBSCRIBE_COURSE= 'SUBSCRIBE_COURSE';
export const COURSE_CONTENT= 'COURSE_CONTENT';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_WEBINARS = 'GET_WEBINARS';
export const REGISTER_WEBINAR_SUCCESS = 'REGISTER_WEBINAR_SUCCESS';
export const REGISTER_WEBINAR_FAIL = 'REGISTER_WEBINAR_FAIL';
export const REGISTER_WEBINAR_INITIAL = 'REGISTER_WEBINAR_INITIAL';
