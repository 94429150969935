import { SUBMITTED } from "./oneonone.types";


export const oneononeReducers = (state = "", action) => {
    switch (action.type) {
      case SUBMITTED:
        return { ...state, status: action.payload };
      default:
        return state;
    }
  };