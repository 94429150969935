import React from "react";
import { useParams } from "react-router-dom";
import WebinarRegister from "./WebinarRegister";
import Layout from "../../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
function WebinarRegistrationWrap() {
  const {id} = useParams();
  return (
    <>
      <Layout>
        <Content>
        <Title level={2}>Register For Webinar</Title>
        <WebinarRegister id = {id}/>
        </Content>
      </Layout>
    </>
  );
}

export default WebinarRegistrationWrap;
