import Layout from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CourseInFo from "./CourseInFo";

const CourseDetail = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const { id } = useParams();
  return (
    <>
      <Layout>
        <Content>
          <CourseInFo id={id} />
        </Content>
      </Layout>
    </>
  );
};

export default CourseDetail;
