import React, { useState } from 'react'
import { Space,InputNumber } from 'antd'
function ItemQuantity(props) {
    const[quantity,setQuantity]= useState(props.details.quantity)
    function OnChange(value) {
        setQuantity(value);
        props.ChangeQuantity(value,props.details.id);
      }
  return (
    <><Space>
        <InputNumber size={props.size} min={1} max={100} value={props.details.quantity} onChange={OnChange} />
        </Space>
    </>
  )
}

export default ItemQuantity


