import React from "react";
import { Link } from "react-router-dom";
import "./CustomCartCard.css";
const CustomCartCard = ({ course, addToCart }) => {
  // console.log(course)
  return (
    <div className="cartCard">
      <div className="cardDetailsSection">
        <div className="imgContainer">
          <img
            src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/course-cart.png"}
            alt="Course"
            style={{ height: "100%" }}
          />
        </div>
        <div className="description">
          <Link to={`/SingleCourse/${course.slug}`}>
            <h3>{course.name}</h3>
          </Link>
          <h4>Software</h4>
          <button
            onClick={(e) => {
              e.preventDefault();
              addToCart(course);
            }}
          >
            Remove from cart
          </button>
            
        </div>
        <div className="price">$&nbsp;{course.price}</div>
      </div>
    </div>
  );
};

export default CustomCartCard;
