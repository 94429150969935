import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form } from "antd";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { CHANGE_PASSWORD } from "../../store/user/user.types";

class ChangePasswordForm extends React.Component {
  onSubmit = (values) => {
    this.props.onSubmit(values);
  };

 

  render() {
    const { user } = this.props;
    return (
      <>
        {user.action === CHANGE_PASSWORD ? <Notifications user={user} /> : null}
        <Form layout="vertical" className="profile-action-form" onFinish={this.props.handleSubmit(this.onSubmit)}>
          <Form.Item>
            <Field name="oldPassword" label="Old Password" component={createField} />
          </Form.Item>
          <Form.Item>
            <Field name="password" label="Password" component={createField} />
          </Form.Item>
          <Form.Item>
            <Field name="confirmPassword" label="Confirm Password" component={createField} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="account-btn">
              Update password
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.oldPassword) {
    errors.email = "This field is Required";
  }
  if (!values.password) {
    errors.password = "This field is Required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

export default reduxForm({
  form: "ChangePassword",
  validate,
})(ChangePasswordForm);
