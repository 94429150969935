import { CLIENT_BASE_URL_AXIOS } from "../../services/apis";
import {
  SUBSCRIPTION_LIST,
  SUBSCRIPTION_DETAIL,
  SORT_MY_COURSES,
} from "./subscription.types";

const SUBSCRIPTIONUrl = process.env.REACT_APP_SUBSCRIPTION_FETCH_ENDPOINT;

export const userBasedsubscriptions = (sort) => async (dispatch, getState) => {
  const subscriptions = getState().subscriptions;
  const uid = getState().user.user.username;
  var response;
  
  try {
    response = await CLIENT_BASE_URL_AXIOS.get(
      SUBSCRIPTIONUrl + "?user_id=" + uid
    );
  } catch (e) {
    response = e.response;
  }
  if (sort) {
    return (
      dispatch({ type: SORT_MY_COURSES, payload: response.data }) &&
      Object.keys(subscriptions.body).length === 0 &&
      dispatch({ type: SUBSCRIPTION_LIST, payload: response.data })
    );
  }
  dispatch({ type: SUBSCRIPTION_LIST, payload: response.data });
};

export const subscriptionDetail =
  (subscriptionId) => async (dispatch, getState) => {
    var response;
    const username = getState().user.user.username;

    try {
      response = await CLIENT_BASE_URL_AXIOS.get(
        SUBSCRIPTIONUrl + "?subscription_id=" + subscriptionId
      );
    } catch (e) {
      response = e.response;
    }
    dispatch({ type: SUBSCRIPTION_DETAIL, payload: response.data });
  };
