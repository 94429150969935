import React, {  useState } from "react";

import { Markup } from 'interweave';
import {
  Button,
  
  
  Badge,
  Row,
  Col,
  Divider,
  Breadcrumb,
 
  Modal,
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import RegForm from "./RegForm";
export const Details = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    console.log(open);
    setOpen(true);
  };
  const closeModal = () => {
    console.log(open);
    setOpen(false);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const web = useSelector((state) => state.webinars);
  
  return (
    <>
      <div className="wrapper">
        <div className="courseDetailsPage">
          <section className="course-details">
            <Row>
       
              <Col span={16}>
                <div className="course-info">
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item className="course-breadcrumb">
                      Webinars
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="course-breadcrumb">
                      {web[props.id].name}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <Divider />
                  <Row>
                    <Col span={6}>
                      <Badge.Ribbon text={`new`} color="volcano">
                        <img
                          alt="data image"
                          src={`${
                            web[props.id].thumbnail
                              ? web[props.id].thumbnail
                              : null
                          }`}
                          width="30%"
                          height="30%"
                        />
                      </Badge.Ribbon>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={17}>
                      <h3>{web[props.id].name}</h3>
                      <p>{web[props.id].description}</p>
                      <p className="web-admin">
                        By : {web[props.id].admin} | <CalendarOutlined />{" "}
                        {web[props.id].webinar_date}
                      </p>
                      <Divider />
                      <div className="course-details-actions">
                        <Button
                          type="primary"
                          onClick={showModal}
                          style={{
                            background: "white",
                            borderColor: "white",
                            color: "#597B65",
                            padding: "10px",
                            height: "50px",
                            borderRadius: "10px",
                          }}
                        >
                          Register Now
                        </Button>
                        <Modal
                          visible={open}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          width={400}
                          footer={[]}
                        >
                          <Row>
                            <Col span={3}></Col>
                            <Col span={20}>
                          <RegForm web={web} id={props.id} closeModal={closeModal}/>
                          </Col>
                          <Col span={1}></Col>
                          </Row>
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col span={8}>
                <div className="svg-col">
                  <svg
                    width="272"
                    height="272"
                    viewBox="0 0 272 272"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5" clip-path="url(#clip0_168_281)">
                      <path
                        d="M136 272C169.071 272 199.385 260.197 222.954 240.566L194.277 198.535C178.991 212.788 158.498 221.538 136 221.538C113.502 221.538 92.3446 212.492 76.9846 197.821L48.2708 239.901C71.9507 259.914 102.572 271.988 136 271.988V272Z"
                        fill="white"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M269.649 110.769C257.821 47.7169 202.486 0 136 0C69.5138 0 14.1785 47.7169 2.35077 110.769C0.812307 118.942 0 127.372 0 136C0 144.628 0.812307 153.058 2.35077 161.231H269.649C271.188 153.058 272 144.628 272 136C272 127.372 271.188 118.942 269.649 110.769ZM136 50.4615C174.388 50.4615 206.941 75.8769 217.735 110.769H54.2646C65.0584 75.8769 97.6123 50.4615 136 50.4615Z"
                        fill="white"
                        fill-opacity="0.1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_168_281">
                        <rect width="272" height="272" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Col>
            </Row>
          </section>
          <Divider/>
          <section>
            <div className="additional-details">
              <h3 className="heading-3">More About This Webinar</h3>
              <div className="details">
              <Markup content={web[props.id].moreinfo} />
                
              </div>
            </div>
          </section>
        </div>
     
      </div>
    </>
  );
};
