import React from "react";
import Layout from "../../components/Layout";
import { List as AntList,  Col,  Row,  Menu } from "antd";
import { Content } from "antd/lib/layout/layout";
import { connect } from "react-redux";
import { list } from "../../store/course/course.actions";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AddToCart } from "../../store/course/course.actions";
import { userBasedsubscriptions } from "../../store/subscription/subscription.actions";
import CustomCard from "../../components/CustomCard/CustomCard";
import "./CoursesLisiting.css";
import { currentUser} from "../../store/user/user.actions";
class List extends React.Component {
  constructor(props) {
    super(props);
    this.props.list(this.state.sortby);
    currentUser();
   
  }
 
  state = {
    sortby: this.props.categorybasedlist ? this.props.categorybasedlist : "all",
    selectedCategory: "Software",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.list(this.state.sortby);
  }

  handleChange = (value, courseName) => {
    this.setState({
      selectedCategory: courseName || value,
    });
    if (value == "mycourses") {
      return this.props.userBasedsubscriptions(true);
    }
    this.props.list(value);
  };
  renderList() {
    const courses = [];
    _.chunk(this.props.items, 3).forEach((category) =>
      category.forEach((course) => courses.push(course))
    );

    return (
      <AntList
        pagination={{
          onChange: (page) => {},
          pageSize: 8,
        }}
        grid={{
          gutter: 16,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        itemLayout="vertical"
        dataSource={courses}
        renderItem={(course) => (
          <AntList.Item>
            <Link to={`/SingleCourse/${course.slug}`}>
              <CustomCard
                course={course}
                cart={this.props.cart}
                addToCart={this.props.AddToCart}
              />
            </Link>
          </AntList.Item>
        )}
      />
    );
  }
  renderSortOptions() {
    if (this.props.categories) {
      return (
        <Menu className="b-0" defaultSelectedKeys={["all"]}>
          {this.props.categories.map((category) => (
            <Menu.Item
              key={category.name}
              onClick={() => this.handleChange(`${category.name}`)}
            >
              {category.name}
            </Menu.Item>
          ))}
          <Menu.Item key="all" onClick={() => this.handleChange("all", "All")}>
            All
          </Menu.Item>
        </Menu>
      );
    } else {
      return null;
    }
  }
  render() {
    if (this.props.items[0] === null) {
      return <div></div>;
    }
    return (
      <Layout>
        <Content>
          <div className="coursesLisitingPage">
            <div
              className="bestCoursesContainer"
              style={{ position: "relative" }}
            >
              <h3>Best courses for you</h3>
              <p>
                Get unlimited access to over 90% of courses, Projects, Specializations, and Professional Certificates.
              </p>
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-one"
              />
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-two"
              />
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-three"
              />
            </div>
            <div className="coursesContainer">
              <Row style={{ justifyContent: "center" }}>
                <Col lg={4} xl={4} xxl={3} className="filterContainer">
                  <h3>Categories</h3>
                  {this.renderSortOptions()}
                </Col>
                <Col lg={20} xl={20} xxl={18} className="coursesList">
                  <h2>{this.state.selectedCategory} Courses </h2>
                  <div className="coursesCardContainer">
                    {this.renderList()}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: Object.values(state.courses),
    cart: Object.values(state.mycart),
    categories: Object.values(state.categories),
    user : state.user,
    subscriptions : state.subscriptions.body
  };
};

export default connect(mapStateToProps, {
  list,
  AddToCart,
  userBasedsubscriptions,
  currentUser
})(List);
