import React from "react";
import { Row, Col, Tabs, Rate } from "antd";
import "./CourseAdditionalDetails.css";
import Index from "../../pages/Oneonone/Index";
import QueryIndex from "../../pages/QueryBoard/Index";

const TopReview = () => (
    <>
        <Rate disabled defaultValue={4} />
        <h5>
            <label>By John Lopez | 12 August 2022</label>
        </h5>
        <p> The third and the final course in the DevOps series will teach how to use AWS Services to control the architecture in order to reach a better operational state.</p>
    </>
);

const CourseAdditionDetails = (props) => {
    return (
        <div className="course-addition-details">
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="About" key="1">
                    <div className="about">
                        <h2>About this course</h2>
                        <p>
                            The first course introduces you to essential AWS products, services, and common solutions. The course covers the fundamental concepts of compute, database, storage, networking, monitoring and security that learners and professionals will need to know when working with AWS.
                        </p>
                        <p>
                            The second course in the specialization discusses topics such as source control, best practices for Continuous Integration, and how to use the right tools to measure code quality, by identifying workflow steps that could be automated.
                        </p>
                        <p>
                            The third course explains how to improve the deployment process with DevOps methodology, and also some tools that might make deployments easier, such as Infrastructure as Code, or IaC, and AWS CodeDeploy.
                        </p>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Reviews" key="2">
                    <div className="reviews-section">
                        <h2>Reviews</h2>
                        <h3>
                            <label>4.0</label>
                            <Rate disabled defaultValue={4} />
                            <span>(10 Reviews)</span>
                        </h3>
                        <h4>TOP REVIEW</h4>
                        {[1, 2].map(() => {
                            return (
                                <Row className="top-reviews">
                                    <Col span={24}>
                                        <TopReview />
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row>
                            <Col span={24}>
                                <button className="view-all-btn">VIEW ALL</button>
                            </Col>
                        </Row>
                    </div>
                </Tabs.TabPane>
                {props.check ? null :
                    <Tabs.TabPane tab="1 on 1" key="3">
                        <div className="reviews-section">
                            <h2>1 on 1</h2>
                            <Index />

                        </div>
                    </Tabs.TabPane>
                }{props.check ? null :
                    <Tabs.TabPane tab="Queryboard" key="4">
                        <div className="reviews-section">
                            <QueryIndex />
                        </div>
                    </Tabs.TabPane>
                }
            </Tabs>

        </div>
    );
};

export default CourseAdditionDetails;
