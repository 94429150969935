import {  Card,  Spin, Col } from 'antd';
import { Link } from 'react-router-dom';

const styles = {
  img: {
    "height": "200px",
    "width": "100%",
  }
}
const CardsLayout = (props) => {
  const { children, cardsData } = props;
    const { Meta } = Card;
    const rendercards = () => {
      return (
        <Card key={cardsData.name} cover={<img alt="example" src={cardsData.thumbnail} style={styles.img} />}>
                <Meta
                  //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title={`${cardsData.name}`}
                  description={`${cardsData.name}`}
                />
              </Card>

       )

      }
  return (<Col sm={24} lg={6} xl={6} style={{width: "350px"}}>
        <Link to={`/category/${cardsData.name}`}>
        {cardsData? rendercards():<Spin></Spin>}
        </Link>
        </Col>)
}


export default CardsLayout;