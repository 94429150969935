import { CC_CREATE_USER, CC_VERIFY_USER,CC_LOGIN_USER_SUCCESS, CC_LOGIN_USER_FAILED,CC_LOGOUT_USER,CC_CURRENT_USER_SUCCESS,CC_CURRENT_USER_FAILED, CC_FORGOT_PASSWORD,CC_RESET_PASSWORD,CC_UPDATE_USER_SUCCESS,CC_CHANGE_PASSWORD, CC_ADDED_TO_GROUP }  from './coursecreators.types';
import { Link } from 'react-router-dom';
import { CognitoUserPool, CognitoUser,AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { change, reset } from 'redux-form';
import history from '../../services/History';
import { userBasedsubscriptions } from '../subscription/subscription.actions';
import AWS from 'aws-sdk'
import { CLIENT_BASE_URL_AXIOS } from '../../services/apis';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_CourseCreator_UserPoolId,
    ClientId: process.env.REACT_APP_CourseCreator_ClientId
});

export const getUserData = (username) => {
    return new CognitoUser({
        Username: username,
        Pool: userPool,
    });
}

export const addUserToGroup = (username) => async (dispatch, getState) => {
    var response;
    const body = JSON.stringify({
        "username": username
    });
    // console.log('adding user to group',username)
    console.log('adding user to group2',body)
    response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_ADD_USER_TO_GROUP,body);
    console.log('adding user to group',username)
    console.log('adding user to group2',response)

}

export const createUser = ({email,password}) => async dispatch => {
    await userPool.signUp(email, password, [], null, ( err, result ) => {
        let response = '';
        if (err) {
            console.log('error is',email,password)
            response = {
                status: false,
                msg: err.message
            };
            dispatch(change('RegistrationForm', 'email', ''));
        } else {
            // console.log('user is ',result.userSub)
            // const params = {
            //     GroupName: 'easy-learn-coursecreator', /* required */ /* required */
            //     Username: result.userSub,
            //     UserPoolId: process.env.REACT_APP_CourseCreator_UserPoolId
            //     /* required */
            //   }
            //   const cognitoidp = new AWS.CognitoIdentityServiceProvider()
            //   console.log('cognitoidp is',cognitoidp)
            //   cognitoidp.adminAddUserToGroup(params, 'ap-south-1',function(err, data) {
            //     if (err) {
            //         console.log('if')
            //     console.log(err, err.stack); // an error occurred
            //     }else {
            //         console.log('else')
            //         console.log(data);
            //                }           // successful response
            //   });
            response = {
                status: true,
                msg: '',
                username: result.user.getUsername(),
                uid : result.userSub
            };
            // const resp = addUserToGroup(result.userSub)
            console.log('resp is',result)
        }
        // const resp = addUserToGroup(result.userSub)
        //     console.log('resp is',resp)
        dispatch({ type: CC_CREATE_USER, payload: response});
    });
};

export const sendCode = () => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);

    await cognitoUser.resendConfirmationCode((err, result) => {
        let response = '';
        if (err) {
            response = {
                status: false,
                msg: err.message,
                codeSent: false,
                username: user.username
            };
        } else {
            response = {
                status: true,
                msg: '',
                codeSent: true,
                username: user.username
            };
        }
        dispatch({ type: CC_LOGIN_USER_FAILED, payload: response});
    });
};


export const verifyUser = code => async (dispatch,getState) => {
    const { user }  = getState().cc_user;
    const cognitoUser = getUserData(user.username);
    console.log('username is ',user.username)
    await cognitoUser.confirmRegistration(code, true, (err, result) => {
        let response = '';
        if (err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                verified: false
            };
            dispatch({ type: CC_VERIFY_USER, payload: response});
        } else {
            response = {
                status: true,
                msg: 'You have been successfully verified',
                username: user.username,
                verified: true,
                uid: user.uid
                
            };
            console.log('afer verification',cognitoUser)
            dispatch({ type: CC_VERIFY_USER, payload: response});
            
            // <Link to='/https://develop.d1j3mx9d0njri.amplifyapp.com/login'></Link>
            // history.push('')
        }
        dispatch(change('RegistrationForm', 'code', ''));
        dispatch(change('LoginForm', 'code', ''));
    });
};

export const loginUser = ({email,password}) => async dispatch => {
    let authenticationDetails = new AuthenticationDetails({
        Username: email,
	    Password: password
    });
    const cognitoUser = getUserData(email);

    let response = '';
    await cognitoUser.authenticateUser(authenticationDetails, {
	    onSuccess: function(result) {
            response = {
                status: true,
                msg: 'You have successfully logged in',
                username: result.idToken.payload.name? result.idToken.payload.name: result.idToken.payload['cognito:username'],
                token: result.getAccessToken().getJwtToken(),
                email: result.idToken.payload.email,
                codeSent: false,
                result: result
            };
            console.log('user is ',result)
            dispatch({ type: CC_LOGIN_USER_SUCCESS, payload: response});
            history.push('/account');
        },
        onFailure: function(err) {
            if(err.message === 'User is not confirmed.') {
                response = {
                    status: false,
                    msg: err.message,
                    username: email,
                    codeSent: false
                };
            } else {
                response = {
                    status: false,
                    msg: err.message,
                    codeSent: false
                };
                dispatch(reset('LoginForm'));
            }
            dispatch({ type: CC_LOGIN_USER_FAILED, payload: response});
        },
    });
};

export const logOut = () => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);
    await cognitoUser.signOut();
    dispatch({ type: CC_LOGOUT_USER});
    window.location = "/"
}

export const currentUser = () => async dispatch => {
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        await cognitoUser.getSession( (err, session) => {
            let response = '';
            if (err) {
                response = {
                    status: false,
                    msg: err.message
                };
            } else {

                response = {
                    status: true,
                    msg: '',
                    username: session.idToken.payload.name? session.idToken.payload.name: session.idToken.payload['cognito:username'],
                    token: session.getAccessToken().getJwtToken(),
                    email: session.idToken.payload.email? session.idToken.payload.email: session.idToken.payload['cognito:username'],
                    sessionData: session
                };
                // NOTE: getSession must be called to authenticate user before calling getUserAttributes
                (async()=>{
                    await cognitoUser.getUserAttributes(function(err, attributes) {
                        if (err) {
                            // Handle error
                            console.log(err);
                        } else {
                            let attribute = [];
                            for (let i = 0; i < attributes.length; i++) {
                              
                                attribute[attributes[i].getName()] = attributes[i].getValue();
                            }
                            response.fullname = attribute.name;
                        }
                        dispatch({ type: CC_CURRENT_USER_SUCCESS, payload: response });
          
                        dispatch(userBasedsubscriptions(response.username))
                    });
                })()
            }
        });
    } else {
        dispatch({ type: CC_CURRENT_USER_FAILED });
    }
}

export const forgotPassword = ({email}) => async dispatch => {
    const cognitoUser = getUserData(email);
    let response = '';
    await cognitoUser.forgotPassword({
        onSuccess: function(data) {
            response = {
                status: true,
                msg: 'If there is account associated with your email, you will recieve the code',
                username: email,
                forgotFormHide: true
            };
            dispatch({ type: CC_RESET_PASSWORD, payload: response});
        },
        onFailure: function(err) {
            response = {
                status: false,
                msg: err.message,
                username: email,
                forgotFormHide: false
            };
            dispatch({ type: CC_FORGOT_PASSWORD, payload: response});
        }
    })
}

export const resetPassword = ({code,password}) => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);
    let response = '';
    await cognitoUser.confirmPassword(code, password, {
        onSuccess() {
            response = {
                status: true,
                msg: 'Password reset completed successfully'
            };
            dispatch({ type: CC_LOGIN_USER_FAILED, payload: response});
            history.push('/login');
        },
        onFailure(err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                forgotFormHide: true
            };
            dispatch(reset('ResetForm'));
            dispatch({ type: CC_RESET_PASSWORD, payload: response});
        },
    });
}

export const updateUser = ({fullname}) => async (dispatch,getState) => {
    var cognitoUser = userPool.getCurrentUser();
    const { user }  = getState().user;
    cognitoUser.signInUserSession = user.sessionData;
    
    if (cognitoUser != null) {

        var attributeList = [];
        var attribute = {
            Name: 'name',
            Value: fullname,
        };
        var name = new CognitoUserAttribute(attribute);
        attributeList.push(name);

        await cognitoUser.updateAttributes(attributeList,(err, result) => {
            let response = '';
            if (err) {
                response = {
                    status: false,
                    msg: err.message,
                    username: user.username,
                    token: user.token,
                    email: user.email,
                    sessionData: user.sessionData
                };
            } else {
                response = {
                    status: true,
                    msg: 'User details updated',
                    username: user.username,
                    token: user.token,
                    email: user.email,
                    fullname: fullname,
                    sessionData: user.sessionData
                };
            }
            dispatch({ type: CC_UPDATE_USER_SUCCESS, payload: response });
        });
    } else {
        dispatch({ type: CC_CURRENT_USER_FAILED });
    }
}

export const changePassword = ({oldPassword,password}) => async (dispatch,getState) => {
    var cognitoUser = userPool.getCurrentUser();
    const { user }  = getState().user;
    cognitoUser.signInUserSession = user.sessionData;

    let response = '';
    await cognitoUser.changePassword(oldPassword, password, (err, result) => {
        if (err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                token: user.token,
                email: user.email,
                sessionData: user.sessionData
            };
            dispatch(reset('ChangePassword'));
        } else {
            response = {
                status: true,
                msg: 'Password Changed',
                username: user.username,
                token: user.token,
                email: user.email,
                sessionData: user.sessionData
            };
        }
        dispatch({ type: CC_CHANGE_PASSWORD, payload: response});
    });
}

