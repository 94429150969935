import { CC_CREATE_USER, CC_VERIFY_USER,CC_LOGIN_USER_SUCCESS, CC_LOGIN_USER_FAILED,CC_LOGOUT_USER,CC_CURRENT_USER_SUCCESS,CC_CURRENT_USER_FAILED, CC_FORGOT_PASSWORD,CC_RESET_PASSWORD,CC_UPDATE_USER_SUCCESS,CC_CHANGE_PASSWORD, CC_ADDED_TO_GROUP }  from './coursecreators.types';

const INITIAL_STATE = {
    isSignedIn: false,
    action: null,
    user: {
        status: null,
        forgotFormHide: false,
        codeSent: false
    }
};

const STATUS_CHECK = {
    session: false
};

const courseCreatorReducers = (state = { ...INITIAL_STATE , ...STATUS_CHECK }, action) => {
    switch (action.type) {
        case CC_CREATE_USER:
            return { ...state,session:true, action: CC_CREATE_USER , user: action.payload};
        case CC_VERIFY_USER:
            return { ...state,session:true, action: CC_VERIFY_USER , user: action.payload};
        case CC_LOGIN_USER_SUCCESS:
            console.log('user is ',action.payload)
            return { ...state,session:true, isSignedIn: true, action: CC_LOGIN_USER_SUCCESS , user: action.payload};
        case CC_LOGIN_USER_FAILED:
            return { ...state,session:true, isSignedIn: false, action: CC_LOGIN_USER_FAILED , user: action.payload};
        case CC_LOGOUT_USER:
            return { ...state,session:true, ...INITIAL_STATE};
        case CC_CURRENT_USER_SUCCESS:
            return { ...state,session:true, isSignedIn: true, user: action.payload};
        case CC_CURRENT_USER_FAILED:
            return { ...state,session:true };
        case CC_UPDATE_USER_SUCCESS:
            return { ...state,session:true, action: CC_UPDATE_USER_SUCCESS , user: action.payload};
        case CC_FORGOT_PASSWORD:
            return { ...state,session:true, action: CC_FORGOT_PASSWORD , user: action.payload};
        case CC_RESET_PASSWORD:
            return { ...state,session:true, action: CC_RESET_PASSWORD , user: action.payload};
        case CC_CHANGE_PASSWORD:
            return { ...state,session:true, action: CC_CHANGE_PASSWORD , user: action.payload};
        default:
            return state;
    }
};

export default courseCreatorReducers;