import React, { useState, useEffect} from "react";
import {  useSelector } from 'react-redux';
import { Form, Input, Modal, Skeleton, Divider } from "antd";
import { Button, DatePicker } from "antd";
const { TextArea } = Input 
const SubmitForm = (props) => {
  const [buttonClass, setButtonClass] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [form] = Form.useForm();
  useEffect(() => {
    handleMsg()
  },[useSelector(state => state.OneOnOne)])
  let status = useSelector(state => state.OneOnOne)

  const handleMsg = () => {
    status ? setResponse() : setLoading(true)
  }

  const setResponse = () => {
    setLoading(false)
    
  }
   
  const onSubmit = (val) => {
    props.OnSubmit(val)
    showModal()
    setButtonClass('bton-type3-clicked')
    console.log(buttonClass)
    
  }
  const onChange = () => {
    setButtonClass('bton-type3')
  }
  const handleOk1 = () => {
    setIsModalOpen(false);
    
    
    form.resetFields();
   
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);

  };
  const showModal = () => {
    setIsModalOpen('true')
  }


    return (
      <>
        <div className="format_button">
          <Form onFinish={onSubmit}
          
          form={form}
          layout="vertical"
          
          >
           
            <Form.Item label="Select date & time " name="request_date" 
                   rules={[
                    {
                      required: true,
                      message: 'Please select the date',
                    },
                  ]}
                  >
              <DatePicker />
            </Form.Item>
            <Form.Item label="Reason for 1 on 1 " name="query"
                  
                   rules={[
                    {
                      required: true,
                      message: 'Please mention the reason for 1 on 1',
                    },
                  ]}>
              <TextArea 
              
              rows={4} cols={100} onFocus={onChange}  placeholder="Type your Reason"/>

             
            </Form.Item>
    
            <Form.Item>
        <Button className={buttonClass} type="primary" htmlType="submit">Send Request</Button>
      </Form.Item>
    
          </Form>
          <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}
      footer={[
        <Button key="Ok" onClick={handleOk1}>
          OK
        </Button>]}
        width={400}
      >
        {loading ? <div className='modal-container'><Skeleton active /> </div>: 
                <div className='modal-container'>
                <Divider/>
             <Divider/>
             <h3>Your request is been registered</h3>
             <span className="text-center">
             <p>You will be notified via email</p>
             <p> Once the request has been accepted.</p>
             </span>
                     </div>
                     }
        

      </Modal>
        </div>
      </>
    );
  }
  export default SubmitForm

