import { message } from "antd";

export const Loading = (text) => {
    const loading = message.loading(text, 0);
    // Dismiss manually and asynchronously
    setTimeout(loading, 2500);
  };

export const success = (text) => {
    const success = message.success(text, 0);
    setTimeout(success,2500)
  };
  
export const error = (text, timeout) => {
    const error = message.error(text);
    setTimeout(error, timeout )
  };