import { Table, Space } from "antd";
import React from "react";
import { connect } from "react-redux";
import { userBasedsubscriptions } from "../../store/subscription/subscription.actions";
import Notifications from "../../components/Notifications";
import { Link } from "react-router-dom";

class SubscriptionHistory extends React.Component {
  componentDidMount() {
    this.props.userBasedsubscriptions();
  }

  columns = [
    {
      title: "subscription Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <Link to={"/subscriptions/" + text}>{text}</Link>,
    },
    {
      title: "Date",
      dataIndex: "creation_date",
      key: "creation_date",
    },
    {
      title: "Course",
      key: "course_id",
      render: (_, column) => (
        <>
          <h1>{column.course_id}</h1>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>Invite {record.name}</a>
          <a>Delete</a>
        </Space>
      ),
    },
  ];

  render() {
    return (
      <>
        {this.props.subscriptions.status != 400 &&
        this.props.subscriptions.status != "" ? (
          <Table
            columns={this.columns}
            dataSource={this.props.subscriptions.body}
            pagination={{ pageSize: 8 }}
          />
        ) : (
          <Notifications user={this.props.subscriptions} />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
    user: state.user,
    courses: Object.values(state.courses),
  };
};

export default connect(mapStateToProps, { userBasedsubscriptions })(
  SubscriptionHistory
);
