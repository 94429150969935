import { Card, Modal } from 'antd';
import React, { useState } from 'react';
import Layout from "../../components/Layout";
import './CourseCreator.css';
import CourseRegForm from './CourseRegForm';


const CourseCreator = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
const test = (values) => {
  console.log(values)
}
const { Meta } = Card;

  return (
    <Layout> 
      <div className="course-creator-area "> 
        <div className="course-hero-area">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-lg-5 col-md-7 col-sm-12">
                <div className="hero-banner-content">
                  <h3>Become a Course Creator Today!</h3>
                  <p>Unleash your expertise and share your knowledge with the world! Become a course creator and inspire others to reach their full potential. With our platform, you can easily build engaging online courses and reach a global audience. Start your journey today and make a difference!
                  </p>
                  <div className="banner-button">
                    <button onClick={showModal}>
                        GET STARTED
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-7"></div>
            </div>
          </div>
        </div>

        {/* So many reasons to start area start */}
        <div className="many-reason-area">
          <div className="container">
            <div className="many-reason-title text-center">
              <h3 >So many reasons to start</h3>
            </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4 col-sm-12">
                  <div className="many-reason-single-column text-center">
                    <div className="many-reason-icon d-flex justify-content-center">
                      <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/teach-icon.png"} />
                    </div>
                    <h4>Teach Your Way</h4>
                    <p>Publish the course you want, in the way you want, and always have control of your own content.</p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="many-reason-single-column text-center">
                    <div className="many-reason-icon d-flex justify-content-center">
                      <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/inspire-icon.png"} />
                    </div>
                    <h4>Teach Your Way</h4>
                    <p>Teach what you know and help learners explore their interests, gain new skills, and advance their careers.</p>
                  </div>
                </div>
                
                <div className="col-lg-4 col-sm-12">
                  <div className="many-reason-single-column text-center">
                    <div className="many-reason-icon d-flex justify-content-center">
                      <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/reward-icon.png"}  />
                    </div>
                    <h4>Teach Your Way</h4>
                    <p>Expand your professional network, build your expertise, and earn money on each paid enrollment.</p>
                  </div>
                </div>
              </div>
          </div>
        </div>

        {/* become an instructor area start */}
        <div className="become-instructor-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className="become-instructor-content">
                  <h3>Become an Instructor Today!</h3>
                  <p>Join one of the world's largest online learning marketplaces.</p>
                  <button className='courseBtn' onClick={showModal}>
                    GET STARTED&nbsp;
                    <img
                      src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/section-four-icon.png"}
                      alt="expand-circle-down"
                      border="0"
                      style={{
                      width: "20px",
                      height: "20px",
                      display: "inline",
                    }}
                  />
                  </button>
                  <Modal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="instructor-modal">
                    <h3 className="text-center">Register for Instructor</h3>
                    <CourseRegForm />
                  </Modal>
                </div>
              </div>
              
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <div className="become-instructor-img">
                  <img src="https://learningmanagementsystem-bucketforthumbnailsdd913-hpmrxr5kxvkn.s3.us-west-1.amazonaws.com/assets/LMS_Imgs/become-instructor.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CourseCreator