import "./VideoItem.css";
import React from "react";

const VideoItem = ({ video, onVideoSelect, selectedVideo }) => {
  const onselectvideo = (video) => {
    onVideoSelect(video);
  };
  return (
    <div onClick={() => onselectvideo(video)} className="video-item item">
      <div className="video-thumbnail-container">
        <img alt={video.name} className="video-thumbnail" src={video.thumbnail} />
        <img
          className="video-status"
          src={
            selectedVideo?.id === video.id
              ? process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/pause-btn-thumbnail.svg"
              : process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/play-thumbnail.svg"
          }
        />
      </div>
      <div className="content">
        <div className="header">{video.name}</div>
      </div>
    </div>
  );
};

export default VideoItem;
