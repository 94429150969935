import React, { useEffect, useState } from "react";
import { CarouselCards } from "./components/CarouselCards";
import "react-multi-carousel/lib/styles.css";
import "./carousel.css";
import CarouselNav from "./components/CarouselNav";

function Slider({ data, navData }) {
  const [activeOption, setActiveOption] = useState(navData[0]);
  const switchOption = (opt) => {
    setActiveOption(opt);
  };
  useEffect(() => {
    setActiveOption(navData[0]);
  }, []);

  return (
    <>
      <CarouselNav
        options={navData}
        name={"Most Popular Courses"}
        activeOption={activeOption}
        action={switchOption}
      ></CarouselNav>
      <CarouselCards data={data} activeOption={activeOption}></CarouselCards>
    </>
  );
}

export default Slider;
