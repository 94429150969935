import { SUBMITTED, GET_QUERIES, QUERY_REPLY_ADDED, IMAGE_UPLOADED } from "./queryboard.types";
import _ from 'lodash';

const INITIAL_STATE = {

};

export const queryboardReducers = (state = "", action) => {
    switch (action.type) {
        case SUBMITTED:
            return { ...state, status: action.payload };
        default:
            return state;
    }
};


export const queryReducers = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case GET_QUERIES:
            
            return { ..._.mapKeys(action.payload.data.body, 'id') };
        case QUERY_REPLY_ADDED:
            return { ..._.mapKeys(action.payload.data.body, 'id') };
        default:
            return state;
    }
};
export const imageUploadedReducers = (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case  IMAGE_UPLOADED :
            return { imageData: action.payload };

        default:
            return state;
    }
};

