import React from "react";
import "./Modal.css";
import { connect } from "react-redux";
import { AddToCart } from "../../store/course/course.actions";
import { ChangeQuantity } from "../../store/course/course.actions";
import { SubscribeCourse } from "../../store/course/course.actions";
import { DetailView } from "../../store/course/course.actions";
// import StripeCheckout from "react-stripe-checkout";
class Modal extends React.Component {
  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }
  render() {
    const { handleAddToCart, handleCheckout, handleModalClose } = this.props;
    const course = this.props.details;
    console.log(course.id);
    // const onToken=(token)=>{
    //   this.props.SubscribeCourse(course,token);
    // };
    return (
      <div className="modal" onClick={(e) => e.preventDefault()}>
        <div class="modal-content">
          <h3>
            Subscribe to course
            <button className="close" onClick={handleModalClose}>
              &times;
            </button>
          </h3>
          <p>You can Proceed to Checkout or add to Cart</p>
          <div className="modal-ctrl">
            <button className="modal-add-to-cart-btn" onClick={handleAddToCart}>
              ADD TO CART
            </button>
        
            {/* <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                        name="Easy Learn" 
                        description="Learn the Future"
                        image=""
                        amount={course.price*100}
                        allowRememberMe
                        token={onToken}
                        opened={this.onOpened}
                        closed={this.onClosed}
                      > */}
                    <button className="modal-checkout-btn" onClick={handleCheckout}>
                        CHECKOUT
                    </button>
                      {/* </StripeCheckout> */}
            
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    details: state.detail.detail,
    cart: state.mycart,
    subscriptions: state.subscriptions.body,
  };
};
export default connect(mapStateToProps, {
  DetailView,
  AddToCart,
  ChangeQuantity,
  SubscribeCourse,
})(Modal);
