import { GET_WEBINARS, REGISTER_WEBINAR_SUCCESS, REGISTER_WEBINAR_FAIL, REGISTER_WEBINAR_INITIAL } from './webinar.types';
import { CLIENT_BASE_URL_AXIOS } from '../../services/apis';

import _ from 'lodash';
import { userBasedsubscriptions } from '../subscription/subscription.actions';


export const getWebinars = (id) => async (dispatch, getState) => {
  var response;
  
  response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_WEBINAR_LISTING_ENDPOINT);
 
  dispatch({ type: GET_WEBINARS, payload: response });
  dispatch({ type: REGISTER_WEBINAR_INITIAL});
};





export const RegisterWebinar = (data) => async(dispatch,getState) =>{
   
    const id = data.webinar_id
    const body =JSON.stringify({
        "name": getState().user.user.username,
        "registered_email":data.email,
        "webinar_id":data.webinar_id,
        "fullname":data.fullname,
        "zoom_url" : getState().webinars[id].link
        });
 
  const response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_WEBINAR_REGISTER_ENDPOINT,
    body, {
    headers: {
      'Content-Type': 'application/json',
      'cognito-token': getState().user.user.token,
      'x-api-key': 'Q23so1jMLq1iYFnb0X1kN9BuYWstcka74PHBudXp'
    }
  });

  console.log('resp', response)
  if (response.data.status === 409) {
    dispatch({ type: REGISTER_WEBINAR_FAIL, payload: response, id: data.webinar_id });
  } else {
    dispatch({ type: REGISTER_WEBINAR_SUCCESS, payload: response, id: data.webinar_id });
  }
  dispatch(userBasedsubscriptions())
};


