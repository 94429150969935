import React, {useState, useEffect } from 'react'
import { Row, Col, Avatar, Space, Form, Input, Button, Divider, Modal, Spin, Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { RegisterWebinar } from './../../../store/webinar/webinar.actions'

const RegForm = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [flag, setFlag] = useState();
  const [flag1, setFlag1] = useState();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log('render')
    handleMsg()
  },[useSelector(state => state.webRegStatus)])
  let status = useSelector(state => state.webRegStatus)
  
  const handleMsg = () => {
    status ? setResponse() : setFlags()
  }

  const setFlags = () => {
    setLoading(true)
    setFlag(false)
    setFlag1(false)
  }
  const setResponse = () => {
    console.log("status is",status.status)
    setLoading(false)
    status.status == 'REGISTER_WEBINAR_SUCCESS' ? setFlag(true) : setFlag1(true)
    
  }

    const dispatch = useDispatch();
    console.log('test')
    const showModal = () => {
      setIsModalOpen(true);
      
    };
    const handleOk1 = () => {
      setIsModalOpen(false);
      setFlag(false)
      setFlag1(false)
    };
    const handleCancel1 = () => {
      setIsModalOpen(false);
      setFlag(false)
      setFlag1(false)
    };

    
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        }, 3000);
      };
      const handleCancel = () => {
        setOpen(false);
      };
    
      const onFinish = (values) => {
        console.log('Success:', values);

        const values2 = {
            webinar_id : props.id,
            email : values.email,
            fullname : values.fullname
        }
        dispatch(RegisterWebinar(values2))
        props.closeModal()
        setLoading(true)
        showModal()
        
        
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
  return (
    <Row>
        <Col span={4}></Col>
        <Col span={20}>
    <div> 
    <h3>Register for Webinar</h3>
    <Divider/>
    <Row>
        <Col span={3}>
            <Avatar src={props.web[props.id].thumbnail}></Avatar>
            </Col>
            <Col span={2}></Col>
            <Col span={19}>
                {props.web[props.id].name}
            </Col>
    </Row> 
    <Divider/>   
                     
    <Form
      name="webReg"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        
        name="fullname"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input placeholder="Full Name"/>
      </Form.Item>
      <Form.Item
        
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email',
          },
        ]}
      >
        <Input placeholder="Email"/>
      </Form.Item>


      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 16,
        }}
      >
        <Row>
          
          <Col >
        <Button type="primary" 
        style={{ background: "#597B65", borderColor: "#597B65" }}
        htmlType="submit"
        >
          Register
        </Button>
        </Col>
        <Col span={24}></Col>
        </Row>
      </Form.Item>

    </Form>

      <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}
      footer={[
        <Button key="Ok" onClick={handleOk1}>
          OK
        </Button>]}
        width={310}
      >
        {loading ? <div className='modal-container'><Skeleton active /> </div>: null}
        {flag ? 
        
        <div className='modal-container'>
           <Divider/>
        <Divider/>
        <h3>Thank you for registering for Webinar</h3>
        <p>Please check your email for webinar link</p>
        <Divider/>
        <Divider/>
                </div>
         : null}
        {flag1 ? 
        <div className='modal-container'>
          <Divider/>
        <Divider/>
        <h3>Email is already registered</h3> 
        <Divider/>
        <Divider/>
        </div>: null}
      </Modal>
    </div>
    </Col>
   

    </Row>
    
  )
}

export default RegForm