import CustomCartIcon from "../components/icon-components/cart";
import About from "../pages/About/about";
import Account from "../pages/Account/Account";
import ChangePassword from "../pages/Account/ChangePassword";
import Subscription from "../pages/Account/Subscription";
import SubscriptionHistory from "../pages/Account/SubscriptionHistory";
import CategoryBasedCourses from "../pages/Category";
import ContactUs from "../pages/ContactUs/ContactUs";
import Category from "../pages/Courses/Category";
import List from "../pages/Courses/List";
import MyCart from "../pages/Courses/MyCart/MyCart";
import Home from "../pages/Home";
import ForgotPassword from "../pages/Login/ForgotPassword";
import Login from "../pages/Login/Login";
import MyCourses from "../pages/MyCourses/MyCourses";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Quiz from "../pages/Quiz/Quiz";
import Register from "../pages/Register/Register";
import CourseWrap from "../pages/SingleCourse/course/";
import CourseDetail from "../pages/SingleCourse/CourseDetail";
import TermsCondition from "../pages/TermsCondition/TermsCondition";
import Webinar from "../pages/webinar";
import WebinarWrap from "../pages/webinar/DetailPage";
import WebinarRegistrationWrap from "../pages/webinar/Register";
import ThankYou from "../pages/ThankYou";
import OneOnOne from "../pages/Oneonone/OneOnOne";
import CourseCreator from "../pages/CourseCreator/CourseCreator";
const routes = [
  {
    path: "/",
    name: "Home",
    element: <Home />,
    isSignedIn: null,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/courses",
    name: "Courses",
    element: <List />,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/register",
    name: "Register",
    element: <Register />,
    isSignedIn: false,
    child: [],
  },
  {
    path: "/forgot-password",
    name: null,
    element: <ForgotPassword />,
    isSignedIn: false,
    child: [],
  },
  {
    path: "/account",
    name: "Account",
    element: <Account />,
    isSignedIn: true,
    account: true,
    HideOnMenu: true,
    child: ["/change-password"],
  },
  {
    path: "/SingleCourse/:id",
    name: "Course Detail",
    element: <CourseDetail />,
    isSignedIn: null,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/about",
    name: "About",
    element: <About />,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/change-password",
    name: "Password update",
    element: <ChangePassword />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    element: <SubscriptionHistory />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/categories",
    name: "categories",
    element: <Category />,
    isSignedIn: null,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/category/:category",
    name: "category",
    element: <CategoryBasedCourses />,
    isSignedIn: null,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/subscriptions/:id",
    name: "subscriptions Details",
    element: <Subscription />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/Webinar/:id",
    name: "webinar Details",
    element: <WebinarWrap />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/register/:id",
    name: "registration",
    element: <WebinarRegistrationWrap />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/course/:id",
    name: "course",
    element: <CourseWrap />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/webinars",
    name: "webinars",
    element: <Webinar />,
    isSignedIn: true,
    HideOnMenu: false,
    child: [],
  },
  {
    path: "/mycart",
    name: "My Cart",
    element: <MyCart />,
    isSignedIn: null,
    icon: <CustomCartIcon />,
    child: [],
  },
  {
    path: "/login",
    name: "Login",
    element: <Login />,
    isSignedIn: false,
    child: [],
  },
  {
    path: "/terms-conditions",
    name: "Terms and conditions",
    element: <TermsCondition />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    element: <PrivacyPolicy />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    element: <ContactUs />,
    isSignedIn: null,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/my-courses",
    name: "My Courses",
    element: <MyCourses />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/thank-you",
    name: "Thank Your",
    element: <ThankYou />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/oneonone",
    name: "OneOnOne",
    element: <OneOnOne />,
    isSignedIn: true,
    HideOnMenu: true,
    child: [],
  },
  {
    path: "/quiz",
    name: "Quiz",
    element: <Quiz />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
  {
    path: "/course-creator",
    name: "Quiz",
    element: <CourseCreator />,
    HideOnMenu: true,
    isSignedIn: null,
    child: [],
  },
];

export default routes;