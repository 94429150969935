import React, { Component } from "react";
import createField from "../../components/Form/createField";
import { Form, Button, Upload, Input } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Field, reduxForm } from 'redux-form';
import "./QueryBoard.css";
const { TextArea } = Input
class SubmitForm extends Component {
    constructor(props) {
        super(props);
    }
    state = { base64: "" };

    encodeImageFileAsURL = (element) => {
        console.log("e", element);
        var file = element.file.originFileObj;
        var reader = new FileReader();
        reader.onloadend = () => {
            console.log("RESULT", reader.result);
            this.setState({ base64: reader.result });
        };
        const str = reader.readAsDataURL(file);
        console.log("str", str);
    };


    onSubmit = (val) => {
        const add_query_values = { ...val, ...this.state };
        console.log("add_query_values", add_query_values);
        this.props.OnSubmit(add_query_values);
        this.props.setQuery(true);
      
    }
    render() {
        return (
            <>
                <div className="query-board">
                    <h2>Add Query</h2>
                    {/* <Form onFinish={this.props.handleSubmit(this.onSubmit)} */}
                    <Form onFinish={this.onSubmit}
                    
                    layout="vertical">
                        {/* <Field name="title" label="Title" type="text" component={createField} />
                        <Field name="description" label="Query description" type="text" component={createField} /> */}
                                    <Form.Item label="Title " name="title" 
                   rules={[
                    {
                      required: true,
                      message: 'Please Enter The Title',
                    },
                  ]}
                  >
              <Input/>
            </Form.Item>
            <Form.Item label="Query Description " name="description"
                  
                   rules={[
                    {
                      required: true,
                      message: 'Please mention the description',
                    },
                  ]}>
              <TextArea 
              
              rows={4} cols={100}   placeholder="Description"/>

             
            </Form.Item>
                        <Form.Item>
                        <Button id="button" type="primary" htmlType="submit">Submit</Button>
                        <Upload
                            onChange={(e) => this.encodeImageFileAsURL(e)}
                            listType="picture"
                            name="image"
                        >
                            <Button icon={<UploadOutlined />}>Upload image</Button>
                        </Upload>
                        </Form.Item>

                    </Form>
                </div>
            </>
        );
    }
}

export default reduxForm({
    form: 'SubmitForm',
    enableReinitialize: true
})(SubmitForm);