import { Content } from 'antd/lib/layout/layout'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/Layout'
import { changePassword } from '../../store/user/user.actions';
import { Col, Row } from 'antd'
import Sidebar from '../../components/Sidebar/Sidebar'
import ChangePasswordForm from './ChangePasswordForm';

class ChangePassword extends Component {
  onSubmit = formValues => {
    console.log("hit")
    this.props.changePassword(formValues);
  }
  render() {
    return (
      <Layout>
        <Row>
          <Col span={6}><Sidebar/></Col>
          <Col span={18}>
            <Content>
              <ChangePasswordForm onSubmit={this.onSubmit} user={this.props.user}/>
            </Content>
          </Col>
        </Row>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(
  mapStateToProps,
  {changePassword}
)(ChangePassword);