import React, { useEffect, useState } from "react";
import VideoDetail from "./components/VideoDetail";
import { Content } from "antd/lib/layout/layout";
import Layout from "../../../components/Layout";
import { useParams, Link } from "react-router-dom";
import Chapters from "./components/Chapters";
import { PageHeader } from "antd";
import "./CourseContent.css";
import CourseAdditionDetails from "../../../components/CourseAdditionalDetails/CourseAdditionDetails";
import ChatbotModal from "../../../components/chatbot/ChatbotModal";


const Course = ({ FecthCourseContent, courses, subscriptions }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    courses[id] &&
      courses[id].hasOwnProperty("chapter_data") &&
      setSelectedVideo(courses[id].chapter_data[0].chapter_content_data[0]);
  }, [courses]);
  useEffect(() => {
    FecthCourseContent(id);
  }, []);
  const subscribed = subscriptions && subscriptions.filter((sub) => sub.course_id == id);
  return (
    <>
      <Layout>
        <Content>
          {courses[id] && courses[id].hasOwnProperty("chapter_data") && (
            <div className="course-content-page">
              <section className="course-player-section">
                <div>
                  <h2>
                    <Link to={`/SingleCourse/${courses[id].slug}`}>
                      <PageHeader
                        className="site-page-header"
                        onBack={() => null}
                        title={courses[id].name}
                        subTitle=""
                      />
                    </Link>
                  </h2>
                  <div className="course-player-container">
                    <VideoDetail video={selectedVideo} subscribed={subscribed} course={courses[id]} />
                    <Chapters selectedVideo={selectedVideo} onVideoSelect={setSelectedVideo} course={courses[id]} />
                  </div>
                </div>
              </section>
              <section className="course-addition-details-section">
                <CourseAdditionDetails />
              </section>
              <section className="chatbot-section">
                <ChatbotModal/>
              </section>
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
};

export default Course;
