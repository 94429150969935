import React from "react";
import { useParams } from "react-router-dom";
import Layout from '../../../components/Layout'
import { Content } from "antd/lib/layout/layout";
import {Details} from "./Details";
function WebinarWrap() {
  const { id } = useParams();
  return (
    <Layout>
      <Content>
        <Details id={id}>WebinarWrap</Details>
      </Content>
    </Layout>
  );
}

export default WebinarWrap;
