import Layout  from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useParams } from "react-router-dom";
import ListCategoryCourses from "./ListCategoryCourses";

function CategoryBasedCourses() {
  const { category } = useParams();
  return (
    <>
      <Layout>
        <Content>
          {category && <ListCategoryCourses category={category} />}
        </Content>
      </Layout>
    </>
  );
}

export default CategoryBasedCourses;
