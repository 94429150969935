import React from "react";
import VideoItem from "./VideoItem";
const VideoList = ({ videos, onVideoSelect, selectedVideo }) => {
  const renderedList = videos.map((video) => {
    return <VideoItem key={video.id.videoId} selectedVideo={selectedVideo} onVideoSelect={onVideoSelect} video={video} />;
  });

  return <div className="ui relaxed divided list video-playlist">{renderedList}</div>;
};

export default VideoList;
