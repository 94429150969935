import Layout from "antd/lib/layout/layout";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./index.css";

const layout = ({ children }) => {
  return (
    <Layout className="layout">
      <Header />
      {children}
      <Footer />
    </Layout>
  );
};

export default layout;
