import { GET_WEBINARS, VIEW ,DETAIL, ADDTOCART, PROD_QUANTITY, GET_CATEGORIES, SUBSCRIBE_COURSE, CATEGORY_BASED, COURSE_CONTENT, REGISTER_WEBINAR_SUCCESS, REGISTER_WEBINAR_FAIL, REGISTER_WEBINAR_INITIAL}  from './webinar.types';
import _ from 'lodash';
import { SORT_MY_COURSES } from '../subscription/subscription.types';
import { success, error } from '../../components/Alerts';
import { userBasedsubscriptions } from '../subscription/subscription.actions';
const INITIAL_STATE = {
   
};

export const webinarReducers = (state = { ...INITIAL_STATE}, action) => {
    switch (action.type) {
        case GET_WEBINARS:
            console.log('payload',action.payload)
            return {  ... _.mapKeys(action.payload.data.body, 'id')};
        default:
            return state;
    }
};
export const webinarRegister = (state = '', action) => {
    switch (action.type) {
        case REGISTER_WEBINAR_INITIAL:
            return  ''
        case REGISTER_WEBINAR_SUCCESS:
            return { status: REGISTER_WEBINAR_SUCCESS}
        case REGISTER_WEBINAR_FAIL:
            return { status: action.payload.data.body}
        
        default:
            return state;
    }
};
