import React from "react";
import { useParams } from "react-router-dom";
import OneOnOne from "./OneOnOne";
function Index() {
  const {id} = useParams();
  return (
    <>
      
        <OneOnOne id = {id}/>
        
      
    </>
  );
}

export default Index;
