import React, { Component } from "react";
import { connect } from "react-redux";
import {
  submitOneonone,
} from "../../store/oneonone/oneonone.actions";

import SubmitForm from "./SubmitForm";



class OneOnOne extends Component {


  onSubmit = (values) => {
    this.props.submitOneonone(values,this.props.id);
  };

  render() {

    return (
      <>

        <SubmitForm OnSubmit={this.onSubmit} />

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};
export default connect(mapStateToProps, {
  submitOneonone
})(OneOnOne);
