import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import { forgotPassword, resetPassword } from "../../store/user/user.actions";
import ForgotForm from "./ForgotForm";
import ResetForm from "./ResetForm";
import "./ForgotPassword.css";
class ForgotPassword extends React.Component {
  onSubmit = (formValues) => {
    if (formValues.email) {
      this.props.forgotPassword(formValues);
    } else {
      this.props.resetPassword(formValues);
    }
  };
  render() {
   
    return (
      <Layout>
        <Content>
          <div className="reset-password">
            <div className="reset-form-container">
              <h2>Password Reset</h2>
              {!this.props.user.user.forgotFormHide ? (
                <ForgotForm onSubmit={this.onSubmit} user={this.props.user} />
              ) : (
                <ResetForm onSubmit={this.onSubmit} user={this.props.user} />
              )}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps, { forgotPassword, resetPassword })(ForgotPassword);
