import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Row, Col } from "antd";
import { Link } from "react-router-dom";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { CREATE_USER, VERIFY_USER } from "../../store/user/user.types";
import "./Register.css";
class RegistrationForm extends React.Component {
  onSubmit = (values) => {
    if (values.code) {
      this.props.verify(values.code);
    } else {
      this.props.onSubmit(values);
    }
  };

  render() {
    const { user } = this.props;
    

    return (
      <>
        {(user.action === CREATE_USER && user.user.status) || (user.action === VERIFY_USER && !user.user.status) ? (
          <Form onFinish={this.props.handleSubmit(this.onSubmit)} className="signupForm">
            <Row>
              <Col span={"24"} className="verificationCodeInfo">
                Verification code has been send to your email.
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Field name="code" placeholder="Enter Code" component={createField} />
                <button className="primaryBtn registerBtn" type="submit">
                  VERIFY
                </button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onFinish={this.props.handleSubmit(this.onSubmit)} className="signupForm">
            <Row>
              <Col span="24">
                <Field name="fullname" placeholder="Full Name" component={createField} />
              </Col>
              <Col span="24">
                <Field name="email" placeholder="Email" component={createField} />
              </Col>
              <Col span="24">
                <Field name="password" placeholder="Password" component={createField} />
              </Col>
              <Col span="24">
                <Field name="confirmPassword" placeholder="Confirm Password" component={createField} />
              </Col>
              <Col span="24">
                {user.action === CREATE_USER && user.user.status ? (
                  <button className="primaryBtn registerBtn" type="submit" disabled>
                    SIGN UP
                  </button>
                ) : (
                  <button className="primaryBtn registerBtn" type="submit">
                    SIGN UP
                  </button>
                )}
              </Col>
              <Col span="24">
                {user.action === CREATE_USER || user.action === VERIFY_USER ? <Notifications user={user} /> : null}
              </Col>
              <Col span="24" style={{ padding: "1.5rem 0" }}>
                <p className="signupFormFooter">
                  Have an account <Link to="/login">Sign-in</Link>
                </p>
              </Col>
            </Row>
          </Form>
        )}
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.fullname) {
    errors.fullname = "This field is Required";
  }
  if (!values.email) {
    errors.email = "This field is Required";
  }
  if (!values.password) {
    errors.password = "This field is Required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

export default reduxForm({
  form: "RegistrationForm",
  validate,
})(RegistrationForm);
