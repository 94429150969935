import React , { useEffect, useState} from 'react'

import { Modal, Button } from 'antd';
import './Chatbot.css'
import Chatbot from './Chatbot';
import { PromptProcess } from './PromptProcess';

const EXIT_PROMPT = '{exit}'
const ChatbotModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
      };
      
      const handleOk = () => {
        setIsModalVisible(false);
      };
      
      const handleCancel = () => {
        setIsModalVisible(false);
        // PromptProcess(EXIT_PROMPT);
      };
 
      
  return (
    <div className='chatbot'>
        <Modal
  title="Ask a question"
  open={isModalVisible}
  footer={null}
  onOk={handleOk}
  onCancel={handleCancel}
  style={{  right: 20, bottom: 10, top: 255, position: 'fixed' }}
>
<Chatbot/>
</Modal>
<Button type="primary" onClick={showModal} style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
    Ask a question to GPT-4
</Button>

    </div>
  )
}

export default ChatbotModal