import { Alert } from "antd";
import React from "react";

export default ({user}) => {
    let notify = '',type = null;

    if (user.user) {
        if (user.user.status) {
            notify = user.user.msg ? user.user.msg: '';
            type = 1
        } else {
            notify = user.user.msg;
            type = 2;
        }
    } else {
        if (user.status === 200) {
            notify = user.body ? user.body: '';
            type = 1
        } else {
            notify = user.body ? user.body: '';
            type = 2;
        }
    }
    return (
        type !== null && notify !== ''? <Alert message={notify} type={type === 1? "success":"error"} />: null
    )
}