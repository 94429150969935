import { CREATE_USER, VERIFY_USER,LOGIN_USER_SUCCESS, LOGIN_USER_FAILED,LOGOUT_USER,CURRENT_USER_SUCCESS,CURRENT_USER_FAILED, FORGOT_PASSWORD,RESET_PASSWORD,UPDATE_USER_SUCCESS,CHANGE_PASSWORD }  from './user.types';

const INITIAL_STATE = {
    isSignedIn: false,
    action: null,
    user: {
        status: null,
        forgotFormHide: false,
        codeSent: false
    }
};

const STATUS_CHECK = {
    session: false
};

const userReducers = (state = { ...INITIAL_STATE , ...STATUS_CHECK }, action) => {
    switch (action.type) {
        case CREATE_USER:
            return { ...state,session:true, action: CREATE_USER , user: action.payload};
        case VERIFY_USER:
            return { ...state,session:true, action: VERIFY_USER , user: action.payload};
        case LOGIN_USER_SUCCESS:
            console.log('user is ',action.payload)
            return { ...state,session:true, isSignedIn: true, action: LOGIN_USER_SUCCESS , user: action.payload};
        case LOGIN_USER_FAILED:
            return { ...state,session:true, isSignedIn: false, action: LOGIN_USER_FAILED , user: action.payload};
        case LOGOUT_USER:
            return { ...state,session:true, ...INITIAL_STATE};
        case CURRENT_USER_SUCCESS:
            return { ...state,session:true, isSignedIn: true, user: action.payload};
        case CURRENT_USER_FAILED:
            return { ...state,session:true };
        case UPDATE_USER_SUCCESS:
            return { ...state,session:true, action: UPDATE_USER_SUCCESS , user: action.payload};
        case FORGOT_PASSWORD:
            return { ...state,session:true, action: FORGOT_PASSWORD , user: action.payload};
        case RESET_PASSWORD:
            return { ...state,session:true, action: RESET_PASSWORD , user: action.payload};
        case CHANGE_PASSWORD:
            return { ...state,session:true, action: CHANGE_PASSWORD , user: action.payload};
        default:
            return state;
    }
};

export default userReducers;