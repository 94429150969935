import React from "react";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { Rate, Card, Spin } from "antd";
import { Link } from "react-router-dom";
export const CarouselCards = ({ data, activeOption }) => {
  const ButtonGroup = ({ next, goToSlide }) => {
    useEffect(() => {
      next();
    }, [activeOption]);
    return null;
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      customButtonGroup={<ButtonGroup activeOption={activeOption} />}
      swipeable={true}
      draggable={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="transform 2s ease"
      transitionDuration={2000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      // deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {data.map((card_data) => {
        return (
          <>
            <Link to={`/SingleCourse/${card_data.slug}`}>
              <div className="carousel-card">
                <img src={card_data.thumbnail}></img>
                <div className="carousel-card-text">
                  <h4>{card_data.name}</h4>
                  <h6>Software</h6>
                  <p>{card_data.description}</p>
                  <p className="rating">4.0</p>{" "}
                  <Rate disabled defaultValue={4} />
                  <h5>${card_data.price}</h5>
                </div>
              </div>
            </Link>
          </>
        );
      })}
      <Link to={`/SingleCourse/AWS-Amplify`}>
      <div className="carousel-card">
        <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/Rectangle41.png"}></img>
        <div className="carousel-card-text">
          <h4>Data Analytics</h4>
          <h6>Software</h6>
          <p>
            Upgrade ypur Knowledge on Analytics and big data handling, get one chapter of free trial
          </p>
          <p className="rating">4.0</p> <Rate disabled defaultValue={4} />
          <h5>$200</h5>
        </div>
      </div>
      </Link>
      <Link to={`/SingleCourse/AWS-Amplify`}>

      <div className="carousel-card">
        <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/Rectangle42.png"}></img>
        <div className="carousel-card-text">
          <h4>Cyber Security </h4>
          <h6>Software</h6>
          <p>
            Learn best practices in cyber Security, with hands on lessons and detailed information on handling AWS ,GCP and azure platforms security.
          </p>
          <p className="rating">2.0</p> <Rate disabled defaultValue={2} />
          <h5>$200</h5>
        </div>
      </div>
      </Link>
      <Link to={`/SingleCourse/AWS-Amplify`}>
      <div className="carousel-card">
        <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/Rectangle43.png"}></img>
        <div className="carousel-card-text">
          <h4>Networking Fundamentals</h4>
          <h6>Software</h6>
          <p>
            Best course for you to become a network engineer, from zero to hero in network engineering
          </p>
          <p className="rating">4.0</p> <Rate disabled defaultValue={4} />
          <h5>$200</h5>
        </div>
      </div>
      </Link>
      <Link to={`/SingleCourse/AWS-Amplify`}>
      <div className="carousel-card">
        <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/Rectangle41.png"}></img>
        <div className="carousel-card-text">
          <h4>Web Designing</h4>
          <h6>Software</h6>
          <p>
            Ulimate guide to Become a "fullstack frontend developer", from design to coding. get your free trial now !. 
          </p>
          <p className="rating">4.0</p> <Rate disabled defaultValue={4} />
          <h5>$200</h5>
        </div>
      </div>
      </Link>
    </Carousel>
  );
};

export default Carousel;
