import React, { Component } from "react";
import { connect } from "react-redux";
import {
    submitQuery,
} from "../../store/queryboard/queryboard.actions";
import SubmitForm from "./SubmitForm";
import { List, Image, Space, Avatar, Comment, Divider, Button, Form } from "antd";
import { LeftOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';



class Queryboard extends Component {
    onSubmit = (values) => {
        console.log(this.props);
        this.props.submitQuery(values, this.props.id);
        this.props.setIsModalOpen(true)
    }


    render() {
        return (
            <>
            <Button type="text" onClick={this.props.showQueryForm }>
            <LeftOutlined /> Back To All Questions</Button>
                <SubmitForm OnSubmit={this.onSubmit} setQuery={this.props.setQuery} showQueryList={this.props.showQueryForm} />
                

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};
export default connect(mapStateToProps, {
    submitQuery
})(Queryboard);
