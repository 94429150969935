import { CREATE_USER, VERIFY_USER,LOGIN_USER_SUCCESS,LOGIN_USER_FAILED,LOGOUT_USER,CURRENT_USER_SUCCESS,CURRENT_USER_FAILED ,FORGOT_PASSWORD ,RESET_PASSWORD, UPDATE_USER_SUCCESS, CHANGE_PASSWORD } from './user.types';
import { CognitoUserPool, CognitoUser,AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { change, reset } from 'redux-form';
import history from '../../services/History';
import { userBasedsubscriptions } from '../subscription/subscription.actions';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_UserPoolId,
    ClientId: process.env.REACT_APP_ClientId
});

export const getUserData = (username) => {
    return new CognitoUser({
        Username: username,
        Pool: userPool,
    });
}

export const createUser = ({email,password}) => async dispatch => {
    await userPool.signUp(email, password, [], null, ( err, result ) => {
        let response = '';
        if (err) {
            response = {
                status: false,
                msg: err.message
            };
            dispatch(change('RegistrationForm', 'email', ''));
        } else {
            response = {
                status: true,
                msg: '',
                username: result.user.getUsername()
            };
        }
        dispatch({ type: CREATE_USER, payload: response});
    });
};

export const sendCode = () => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);

    await cognitoUser.resendConfirmationCode((err, result) => {
        let response = '';
        if (err) {
            response = {
                status: false,
                msg: err.message,
                codeSent: false,
                username: user.username
            };
        } else {
            response = {
                status: true,
                msg: '',
                codeSent: true,
                username: user.username
            };
        }
        dispatch({ type: LOGIN_USER_FAILED, payload: response});
    });
};


export const verifyUser = code => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);

    await cognitoUser.confirmRegistration(code, true, (err, result) => {
        let response = '';
        if (err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                verified: false
            };
            dispatch({ type: VERIFY_USER, payload: response});
        } else {
            response = {
                status: true,
                msg: 'You have been successfully verified',
                username: user.username,
                verified: true
            };
            dispatch({ type: VERIFY_USER, payload: response});
            history.push('/login')
        }
        dispatch(change('RegistrationForm', 'code', ''));
        dispatch(change('LoginForm', 'code', ''));
    });
};

export const loginUser = ({email,password}) => async dispatch => {
    let authenticationDetails = new AuthenticationDetails({
        Username: email,
	    Password: password
    });
    const cognitoUser = getUserData(email);

    let response = '';
    await cognitoUser.authenticateUser(authenticationDetails, {
	    onSuccess: function(result) {
            response = {
                status: true,
                msg: 'You have successfully logged in',
                username: result.idToken.payload.name? result.idToken.payload.name: result.idToken.payload['cognito:username'],
                token: result.getAccessToken().getJwtToken(),
                email: result.idToken.payload.email,
                codeSent: false,
                result: result
            };
            console.log('user is ',result)
            dispatch({ type: LOGIN_USER_SUCCESS, payload: response});
            history.push('/account');
        },
        onFailure: function(err) {
            if(err.message === 'User is not confirmed.') {
                response = {
                    status: false,
                    msg: err.message,
                    username: email,
                    codeSent: false
                };
            } else {
                response = {
                    status: false,
                    msg: err.message,
                    codeSent: false
                };
                dispatch(reset('LoginForm'));
            }
            dispatch({ type: LOGIN_USER_FAILED, payload: response});
        },
    });
};

export const logOut = () => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);
    await cognitoUser.signOut();
    dispatch({ type: LOGOUT_USER});
    window.location = "/"
}

export const currentUser = () => async dispatch => {
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        await cognitoUser.getSession( (err, session) => {
            let response = '';
            if (err) {
                response = {
                    status: false,
                    msg: err.message
                };
            } else {

                response = {
                    status: true,
                    msg: '',
                    username: session.idToken.payload.name? session.idToken.payload.name: session.idToken.payload['cognito:username'],
                    token: session.getAccessToken().getJwtToken(),
                    email: session.idToken.payload.email? session.idToken.payload.email: session.idToken.payload['cognito:username'],
                    sessionData: session
                };
                // NOTE: getSession must be called to authenticate user before calling getUserAttributes
                (async()=>{
                    await cognitoUser.getUserAttributes(function(err, attributes) {
                        if (err) {
                            // Handle error
                            console.log(err);
                        } else {
                            let attribute = [];
                            for (let i = 0; i < attributes.length; i++) {
                              
                                attribute[attributes[i].getName()] = attributes[i].getValue();
                            }
                            response.fullname = attribute.name;
                        }
                        dispatch({ type: CURRENT_USER_SUCCESS, payload: response });
          
                        dispatch(userBasedsubscriptions(response.username))
                    });
                })()
            }
        });
    } else {
        dispatch({ type: CURRENT_USER_FAILED });
    }
}

export const forgotPassword = ({email}) => async dispatch => {
    const cognitoUser = getUserData(email);
    let response = '';
    await cognitoUser.forgotPassword({
        onSuccess: function(data) {
            response = {
                status: true,
                msg: 'If there is account associated with your email, you will recieve the code',
                username: email,
                forgotFormHide: true
            };
            dispatch({ type: RESET_PASSWORD, payload: response});
        },
        onFailure: function(err) {
            response = {
                status: false,
                msg: err.message,
                username: email,
                forgotFormHide: false
            };
            dispatch({ type: FORGOT_PASSWORD, payload: response});
        }
    })
}

export const resetPassword = ({code,password}) => async (dispatch,getState) => {
    const { user }  = getState().user;
    const cognitoUser = getUserData(user.username);
    let response = '';
    await cognitoUser.confirmPassword(code, password, {
        onSuccess() {
            response = {
                status: true,
                msg: 'Password reset completed successfully'
            };
            dispatch({ type: LOGIN_USER_FAILED, payload: response});
            history.push('/login');
        },
        onFailure(err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                forgotFormHide: true
            };
            dispatch(reset('ResetForm'));
            dispatch({ type: RESET_PASSWORD, payload: response});
        },
    });
}

export const updateUser = ({fullname}) => async (dispatch,getState) => {
    var cognitoUser = userPool.getCurrentUser();
    const { user }  = getState().user;
    cognitoUser.signInUserSession = user.sessionData;
    
    if (cognitoUser != null) {

        var attributeList = [];
        var attribute = {
            Name: 'name',
            Value: fullname,
        };
        var name = new CognitoUserAttribute(attribute);
        attributeList.push(name);

        await cognitoUser.updateAttributes(attributeList,(err, result) => {
            let response = '';
            if (err) {
                response = {
                    status: false,
                    msg: err.message,
                    username: user.username,
                    token: user.token,
                    email: user.email,
                    sessionData: user.sessionData
                };
            } else {
                response = {
                    status: true,
                    msg: 'User details updated',
                    username: user.username,
                    token: user.token,
                    email: user.email,
                    fullname: fullname,
                    sessionData: user.sessionData
                };
            }
            dispatch({ type: UPDATE_USER_SUCCESS, payload: response });
        });
    } else {
        dispatch({ type: CURRENT_USER_FAILED });
    }
}

export const changePassword = ({oldPassword,password}) => async (dispatch,getState) => {
    var cognitoUser = userPool.getCurrentUser();
    const { user }  = getState().user;
    cognitoUser.signInUserSession = user.sessionData;

    let response = '';
    await cognitoUser.changePassword(oldPassword, password, (err, result) => {
        if (err) {
            response = {
                status: false,
                msg: err.message,
                username: user.username,
                token: user.token,
                email: user.email,
                sessionData: user.sessionData
            };
            dispatch(reset('ChangePassword'));
        } else {
            response = {
                status: true,
                msg: 'Password Changed',
                username: user.username,
                token: user.token,
                email: user.email,
                sessionData: user.sessionData
            };
        }
        dispatch({ type: CHANGE_PASSWORD, payload: response});
    });
}

