import React, { Component } from "react";
import createField from "../../../components/Form/createField";
import { Form } from "antd";
import { Field, reduxForm } from 'redux-form';
import { Button } from "antd";
class WebinarRegisterForm extends Component {
  onSubmit = (val) => {
    this.props.OnSubmit(val)
  }
  render() {
    return (
      <>
        <div className="format_button">
          <Form onFinish={this.props.handleSubmit(this.onSubmit)}>
            <Field name="email" label="Provide your Email" component={createField} />
            <Field name="fullname" label="Provide your Full Name" component={createField} />
            <Button type="primary" htmlType="submit">Register</Button>
          </Form>
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'WebinarRegisterForm',
})(WebinarRegisterForm);