import React, { Component } from "react";
import WebinarRegisterForm from "./WebinarRegisterForm";
import { connect } from "react-redux";
import {
  getWebinars,
  RegisterWebinar,
} from "../../../store/webinar/webinar.actions";
import {
  REGISTER_WEBINAR_SUCCESS
  
} from "../../../store/webinar/webinar.types";
import VerticalList from "../../../components/VerticalList";
import { Loading } from '../../../components/Alerts';
import history from '../../../services/History';



class WebinarRegister extends Component {
  componentDidMount() {
    this.props.getWebinars();
  }

  onSubmit = (values) => {
    values.webinar_id = this.props.id;
    this.props.RegisterWebinar(values);
  };

  render() {

    var gapi = window.gapi;
    var google = window.google;
    /* 
    Update with your own Client Id and Api key 
  */
    var CLIENT_ID =
      "543338058816-au6e5ivjslqvc7rlvspfo1qugjj1hab2.apps.googleusercontent.com";
    // var API_KEY = "543338058816-hjk1g9jo7t2924n0cisdmlu09n5ltlrm.apps.googleusercontent.com"
    var API_KEY = "AIzaSyAhRsUqf4FvwXnOWNqQBpMkZffQ8rqU5ys";
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ];
    var SCOPES = "https://www.googleapis.com/auth/calendar.events";



    const intializeGapiClient = async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOCS],
      });
    };
    gapi.load("client", intializeGapiClient());
    let tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: "", // defined later
    });
    const handleclick = () => {
      tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
          throw resp;
        }

        await AddEvent();
      };
      if (gapi.client.getToken() === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: "consent" });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: "" });
      }
    };

    async function AddEvent() {
      let response;
      try {
        const request = {
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime',
        };
        console.log('cal1', gapi.client.calendar.events)
        response = await gapi.client.calendar.events.list(request);
        console.log('cal success', response)
      } catch (err) {
        console.log('cal err', err);
        return;
      }
    }

    // const handleClick1 = () =>{
    //   function start() {
    //     gapi.client.init({
    //       clientId: CLIENT_ID,
    //       scope: SCOPES,
    //     });
    //   }
    //   gapi.load("client:auth2", start);
    // }
    // const handleClick = () => {
    //   gapi.load('client:auth2', () => {
    //     console.log('loaded client')

    //   gapi.client.init({
    //       apiKey: API_KEY,
    //       clientId: CLIENT_ID,
    //       discoveryDocs: DISCOVERY_DOCS,
    //       scope: SCOPES,
    //       Plugin_name: 'webinar-registratration-lms'
    //     })

    //     gapi.client.load('calendar', 'v3', () => console.log('bam!'))

    //     gapi.auth2.getAuthInstance().signIn()
    //     .then(() => {

    //       var event = {
    //         'summary': 'Awesome Event!',
    //         'location': '800 Howard St., San Francisco, CA 94103',
    //         'description': 'Really great refreshments',
    //         'start': {
    //           'dateTime': '2022-09-15T09:00:00-07:00',
    //           'timeZone': 'America/Los_Angeles'
    //         },
    //         'end': {
    //           'dateTime': '2022-09-15T10:00:00-07:00',
    //           'timeZone': 'America/Los_Angeles'
    //         },
    //         'recurrence': [
    //           'RRULE:FREQ=DAILY;COUNT=2'
    //         ],
    //         'attendees': [
    //           {'email': 'lpage@example.com'},
    //           {'email': 'sbrin@example.com'}
    //         ],
    //         'reminders': {
    //           'useDefault': false,
    //           'overrides': [
    //             {'method': 'email', 'minutes': 24 * 60},
    //             {'method': 'popup', 'minutes': 10}
    //           ]
    //         }
    //       }

    //       var request = gapi.client.calendar.events.insert({
    //         'calendarId': 'primary',
    //         'resource': event,
    //       })

    //       request.execute(event => {
    //         console.log(event)
    //         window.open(event.htmlLink)
    //       })

    /*
            Uncomment the following block to get events
        */
    /*
        // get events
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          const events = response.result.items
          console.log('EVENTS: ', events)
        })
        */

    //     })
    //   })
    // }
    const config = {
      clientId: CLIENT_ID,
      apiKey: API_KEY,
      scope: "https://www.googleapis.com/auth/calendar",
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      ],
    };
    var event = {
      summary: "Awesome Event!",
      location: "800 Howard St., San Francisco, CA 94103",
      description: "Really great refreshments",
      start: {
        dateTime: "2022-09-15T09:00:00-07:00",
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: "2022-09-15T10:00:00-07:00",
        timeZone: "America/Los_Angeles",
      },
      recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
      attendees: [
        { email: "lpage@example.com" },
        { email: "sbrin@example.com" },
      ],
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 10 },
        ],
      },
    };
    

    const addCalendarEvent = () => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        });
        gapi.client.load("calendar", "v3");
        //time zone list:
        // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        let timeZone = "Asia/Jerusalem";
        let duration = "00:30:00"; //duration of each event, here 30 minuts
        //event start time - im passing datepicker time, and making it match //with the duration time, you can just put iso strings:
        //2020-06-28T09:00:00-07:00'
        let startDate = new Date(1670090972009);
        let msDuration =
          (Number(duration.split(":")[0]) * 60 * 60 +
            Number(duration.split(":")[1]) * 60 +
            Number(duration.split(":")[2])) *
          1000;
        let endDate = new Date(startDate.getTime() + msDuration);
        let isoStartDate = new Date(
          startDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .split(".")[0];
        let isoEndDate = new Date(
          endDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .split(".")[0];
        //sign in with pop up window
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(() => {
            let event = {
              summary: "clientName", // or event name
              location: "address", //where it would happen
              start: {
                dateTime: isoStartDate,
                timeZone: timeZone,
              },
              end: {
                dateTime: isoEndDate,
                timeZone: timeZone,
              },
              recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
              reminders: {
                useDefault: false,
                overrides: [{ method: "popup", minutes: 20 }],
              },
            };
            //if you need to list your events than keep it
            gapi.client.calendar.events
              .list({
                calendarId: "primary",
                timeMin: new Date().toISOString(),
                showDeleted: false,
                singleEvents: true,
                maxResults: 10,
                orderBy: "startTime",
              })
              .then((response) => {
                const events = response.result.items;
                console.log("EVENTS: ", events);
              });
            //end of event listing
            let request = gapi.client.calendar.events.insert({
              calendarId: "primary",
              resource: event,
            });
            request.execute((event) => {
              console.log(event);
              window.open(event.htmlLink);
            });
          });
      });
    };

    return (
      <>
        {Object.values(this.props.webinars).length !== 0 && (
          <VerticalList
            LinkPath={"/Webinar"}
            ListData={[this.props.webinars[this.props.id]]}
          />
        )}
        {(this.props.status ?
          (this.props.webRegStatus.status === REGISTER_WEBINAR_SUCCESS) ? history.push('/thank-you') : Loading(this.props.webRegStatus.status) :
          null
        )}


        <WebinarRegisterForm OnSubmit={this.onSubmit} />

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    webinars: state.webinars,
    webRegStatus: state.webRegStatus,
    status: state.webRegStatus.status
  };
};
export default connect(mapStateToProps, {
  RegisterWebinar,
  getWebinars,
})(WebinarRegister);
