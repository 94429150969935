import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Input, List, Spin } from "antd";
import { PromptProcess } from "./PromptProcess";
import axios from "axios";
let hits;
let index;
let promts = '';
function Chatbot() {
  const [queries, setQueries] = useState([]);
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(false);
  
  const conversationContainerRef = useRef(null);
  const course = useSelector((state) => state.courses);
  
  const username = process.env.REACT_APP_ELASTIC_UNAME; // replace with your Elasticsearch username
  const password = process.env.REACT_APP_ELASTIC_PWD; // replace with your Elasticsearch password
  const DEFAULT_PROMPT = 'you can remember this thread as lms thread and whatever conversation we will have henceforward, you will have to remember it till i send {exit}'
  
  useEffect(() => {
    conversationContainerRef.current.scrollTop = conversationContainerRef.current.scrollHeight;
  }, [conversation]);
  useEffect(() => {
    Object.keys(course).map((key) => (
      index = course[key].id
  ))
  checkIndexExists(index);
  // PromptProcess(DEFAULT_PROMPT); 
  }, []);
  const handleInput = (e) => {

    setInput(e.target.value);
    
    
  };

  const checkIndexExists = async (index) => {
    
    try {
      // Check if index exists
      const response = await axios.head(process.env.REACT_APP_ELASTIC_URL+ index,
         {
              auth: {
                username: username,
                password: password
              }
            }
            )
      if (response.status === 200) {
        // console.log(`Index '${index}' already exists`);
        setExist(true);
        return true;
      }else{
        // console.log(`Index '${index}' does not exist`);
        return false;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 404) {
      return false;
    }
  }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // console.log(query);
    // setQueries([query]);
    // add inputs to prompt and add line break after each input
    promts += input + ' ' + '.'
    // console.log(promts);
    // setQueries([input]);

    setLoading(true);
    // Add user's message to conversation
    setConversation((prevConversation) => [
      ...prevConversation,
      { speaker: "User", message: input },
    ]);

    // Show loading spinner
    setLoading(true);
   //iterate through the course and assigne the course id to the index
    
    
  
 
    // Determine bot's response based on user's message
    if(exist){
      
    try {
      //make api call to elasticsearch first
      
      axios.get(process.env.REACT_APP_ELASTIC_URL+index+'/_search?q='+input,{
        auth: {
          username: username,
          password: password
        }
      })
      .then(response => {
         hits = response.data.hits.hits.length;
         setLoading(true);

        const dataArr = response.data.hits.hits;
        let newDataArr = ''

        dataArr.forEach(dataObj => {
          const data = dataObj._source.data;
          newDataArr += data+"\n";
        });
        
        
        axios.post(process.env.REACT_APP_CHATGPT+"api/prompt/getprompt/6401f471cc5aa7907a058642", { 'description': 'remember this chunk of data because I might ask you questions from this data'+' : '+newDataArr })
        .then((response) => {
          
          axios.post(process.env.REACT_APP_CHATGPT+"api/prompt/getprompt/6401f471cc5aa7907a058642", { 'description': input+". You can give me answers based on the data chunk which i passed you in brackets or if answer is not present in data chunk than you can give answer from yourself and Please dont mention from where you found the data. Here is the data: ("+newDataArr+")"})
          .then((response) => {
                                // console.log('final');
                                const botResponse = response.data.message.substring(1);
                                // Add bot's message to conversation
                                setConversation((prevConversation) => [
                                  ...prevConversation,
                                  { speaker: "Bot", message: botResponse },
                                ]);
                              setLoading(false);
                              })
            .catch((error) => {
                                console.error(error);
                                setLoading(false);
                               });
        })
        .catch((error) => {
          console.error(error);
        });
      })
      .catch(error => {
        console.error(error);
      });

    } catch (error) {
      console.error(error);
    }
  }else{
    try {
            // Make API call and get bot's response
      const response = await axios.post(process.env.REACT_APP_CHATGPT+"api/prompt/getprompt/6401f471cc5aa7907a058642", { 'description': input });
      console.log('no index is present',response);
      const botResponse = response.data.message;

      // Add bot's message to conversation
      setConversation((prevConversation) => [
        ...prevConversation,
        { speaker: "Bot", message: botResponse },
      ]);

    }
    catch(error){
      console.error(error);
    }
  }
  // Hide loading spinner
    setLoading(false);
  // Clear input field
    setInput("");
  };

  return (
    <div className="chatbot-container" style={{ position: "relative" }}>
      <div className="chatbot-conversation-container" style={{ 
          position: "relative",
          height: 350,
          width:475,
          
          /* replace with the height of chatbot-input-container */
          overflowY: "auto"
        }}
        ref={conversationContainerRef}
      >
        <List
          dataSource={conversation}
          renderItem={(item) => (
            <List.Item>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{item.speaker}:</span>
                <span>{item.message}</span>
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className="chatbot-input-container">
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={input}
            onChange={handleInput}
            addonAfter={
              <>
                {loading ? (
                  <Spin />
                ) : (
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "8px 16px",
                      fontWeight: "bold",
                    }}
                  >
                    Send
                  </button>
                )}
              </>
            }
          />
        </form>
      </div>
    </div>
  );
}

export default Chatbot;
