import { SUBSCRIPTION_LIST, SUBSCRIPTION_DETAIL } from "./subscription.types";

const INITIAL_STATE = {
  body: "",
  status: "",
  detail: {},
};

const subscriptionReducers = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case SUBSCRIPTION_LIST:
      if (action.payload.status === 200) return { ...state, ...action.payload };
      return {...state, status: 400}
    case SUBSCRIPTION_DETAIL:
      return { ...state, ...{ detail: action.payload } };
    default:
      return state;
  }
};

export default subscriptionReducers;
