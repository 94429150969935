import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

import routes from '../../constants/routes'
import "./index.css";
function ProfileMenu() {
    const menuItemsAllowed= ["My Courses", "My Cart", "Account"]
    let menuItems= routes.filter((item)=>
    menuItemsAllowed.includes(item.name)
    )
   
  return (
    <>
      {menuItems.map((item)=>{
        return (
            <Menu.Item key={item.name} className="profileMenuItem">
              <Link to={item.path} className="navMenu">
                {item.name}
              </Link>
            </Menu.Item>
    
        );
      })}
   
    
    </>
  )
}


export default ProfileMenu
