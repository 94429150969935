import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import Layout from "../../components/Layout";
import { currentUser, updateUser } from "../../store/user/user.actions";
import AccountForm from "./AccountForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { changePassword } from "../../store/user/user.actions";
import "./Account.css";
class Account extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.currentUser();
  }
  updateUserDetails = (formValues) => {
    this.props.updateUser(formValues);
  };

  onSubmit = (formValues) => {
    this.props.changePassword(formValues);
  };

  render() {
    return (
      <Layout>
        <Content>
          <div className="profile-page">
            <div>
              <div className="profile-container">
                <h2>Profile</h2>
                <div>
                  <div className="profile-picture">
                    <img
                      src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/avatar.png"}
                      alt=""
                    />
                  </div>
                  <div className="profile-details">
                    {/* <h3>James Hardley</h3> */}
                    <h4>
                      {this.props.user.user.email &&
                        this.props.user.user.email}
                    </h4>
                   
                  </div>
                </div>
              </div>
              <div className="profile-actions">
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Profile information" key="1">
                    <AccountForm
                      initialValues={_.pick(
                        this.props.user.user,
                        "fullname",
                        "email"
                      )}
                      onSubmit={this.updateUserDetails}
                      user={this.props.user}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Password Update" key="2">
                    <ChangePasswordForm
                      onSubmit={this.onSubmit}
                      user={this.props.user}
                    />
                  </Tabs.TabPane>
                
                </Tabs>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps, { currentUser, updateUser,changePassword })(Account);
