import React, { Component } from "react";
import { connect } from "react-redux";
import { getWebinars } from "../../store/webinar/webinar.actions";
import Layout from "../../components/Layout";
import VerticalList from "../../components/VerticalList";
import { Content } from "antd/lib/layout/layout";
import { Avatar, Card, List, Space, Badge, Divider,Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import "./webinarListing.css";
import { Link } from "react-router-dom";
class Webinar extends Component {
  componentDidMount() {
    this.props.getWebinars();
  }
  render() {
    return (
      <>
        <Layout>
        <div
              className="webinarListContainer bestCoursesContainer"
              style={{ position: "relative" }}
            >
              <h3>Webinars you must attend</h3>
              <p>
                Get unlimited access to over 90% of courses, Projects, Specializations, and Professional Certificates.
              </p>
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-one"
              />
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-two"
              />
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
                className="bg-img-three"
              />
            </div>   
  <div className="webinarListing">   

   <Content>
    
    <Card title={<div className="webinar-title">E-Learning Webinars</div>}
    bordered={false}>
    <List 
          size="large"
          itemLayout="horizontal"
          dataSource={this.props.webinars? Object.values(this.props.webinars) : null}
          renderItem={(item) => (
        
      <Card  hoverable 
  
      style={{
        marginTop: 16,
      }}
      >
        
      <List.Item> 
            
        <List.Item.Meta
          avatar={<Badge.Ribbon text={`new`} color="volcano">
          <Link to={`/Webinar/${item.id}`}>
          <Avatar shape="square" size={104} src={item.thumbnail} />
          </Link>
          </Badge.Ribbon>}
          
          title={<Link to={`/Webinar/${item.id}`}><div className="web-subheading pad">{item.name}</div></Link>}
          description={<div className="web-desc pad">{item.description.substring(0,80)+"..."}
          <h3 className="web-admin">
            By : {item.admin} |  <CalendarOutlined /> {item.webinar_date}
          </h3>
          <div>
            <Link to={`/Webinar/${item.id}`}>
          <Button type="primary"
          style={{ background: "#597B65", borderColor: "#597B65"}}
          >More...</Button>
          </Link>
          </div>
          </div>}

        />

      </List.Item>
     
      
      </Card>
       
      
    )}
    pagination={{
      onChange: (page) => {
        console.log(page);
      },
      pageSize: 5,
    }}
  />
  </Card>
  
          
          </Content>
          </div>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    webinars: state.webinars
  };
};
export default connect(mapStateToProps, {
  getWebinars,
})(Webinar);
