import React from "react";
import { Form, Input } from "antd";

const { TextArea } = Input
const createField = ({ input, label, meta, type,placeholder }) => {
  const { name } = input;
  const hasError = meta.touched && meta.invalid;

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className="ant-form-item-explain ant-form-item-explain-connected">
          <div className="ant-form-item-explain-error">{error}</div>
        </div>
      );
    }
  };

  const itemClass = hasError ? "ant-form-item-with-help ant-form-item-has-error" : null;
  return name === "password" || name === "confirmPassword" || name === "oldPassword" ? (
    <Form.Item className={itemClass} label={label}>
      <Input.Password {...input} placeholder={placeholder} />
      {renderError(meta)}
    </Form.Item>
  ) : (
    <Form.Item className={itemClass} label={label}>
      {type==="textarea" ? <TextArea rows={4} /> : <Input type={type}{...input} placeholder={placeholder} />}

      {renderError(meta)}
    </Form.Item>
  );
};

export default createField;
