import React from 'react'
function CarouselNav({name,options,action,activeOption}) {
    if(options){
  return (
    <>
    <h2>{name}</h2>
    <div className="tab-item">
        {options.map((option)=>{
            return(
            <button key={option} onClick={(e)=>{e.preventDefault();action(option)}} className={activeOption === option?"btn-active": "btn-inactive"} >{option}</button>
            )
        })}
    </div>
    </>
  )
    }else{
    return null
    }
}

export default CarouselNav