import Layout from "../../components/Layout";
import Carousel from "../../components/Carousel";

import { Col, Row } from "antd";

import { list } from "../../store/course/course.actions";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Homepage.css";
import _ from "lodash";
import { getCategories } from "../../store/course/course.actions";
class Home extends Component {
  componentDidMount() {
    this.props.list("all");
    this.props.getCategories("all");
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <div className="homepageContainer">
          <section className="sectionOne">
            <div className="background-img">
              <Row style={{ paddingTop: "100px" }}>
                <Col span={12} className="one-leftSection"></Col>
                <Col span={12} className="one-rightSection">
                  <h1>
                    We help you to<br></br> build your skills
                  </h1>
                  <p>
                    Unlimited access to 7,000+ world-class courses, hands-on projects, and job-ready certificate
                    programs, for one all-inclusive subscription price.
                  </p>
                  <Link to={"/courses"}>
                    <button className="courseBtn" style={{ cursor: "pointer" }}>
                      view our courses
                    </button>
                  </Link>
                </Col>
              </Row>
            </div>
          </section>
          <section className="sectionTwo">
            <Row>
              <Col lg={7} xl={7} xxl={7} className="leftSubSection">
                <h3>Creative way to learn new thing</h3>
                <p>
                  Get unlimited access to over 90% of courses, Projects, Specializations, and Professional Certificates.
                </p>
              </Col>
              <Col lg={14} xl={14} xxl={14} className="middleSubSection">
                <Row style={{ justifyContent: "space-evenly", width: "100%" }}>
                  {this.props.categories &&
                    Object.values(this.props.categories).map((category) => {
                      return (
                        <Col lg={7} xl={7} xxl={7}>
                          <Link to={`/courses`}>
                            <div className="cardwrap">
                              <div className="cards">
                                <img
                                  src={category.thumbnail}
                                  alt={category.name}
                                  style={{ objectFit: "scale-down", padding: 0, aspectRatio: "1/1" }}
                                />
                              </div>
                              <div className="card-title">{category.name}</div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
              <Col lg={3} xl={3} xxl={3} className="rightSubSection">
                <Link to="/courses">VIEW MORE</Link>
              </Col>
            </Row>
          </section>
          <section className="sectionThree">
            <div>
              {this.props.courses && this.props.categories && (
                <Carousel
                  data={Object.values(this.props.courses)}
                  navData={_(Object.values(this.props.categories)).map("name").value()}
                />
              )}
            </div>
          </section>
          <section className="sectionFour">
            <Row>
              <Col lg={12} xl={12} xxl={12} className="four-leftSection">
                <h1>
                  Start E-Learning <br></br>Business
                </h1>
                <p>
                  Want to become a tutor ? we provide you with the best platform in the industry to teach , impart your
                  knowlegde to the world. start your 1 month free trial now !
                </p>
                <Link to="/course-creator" className="detailsBtn">
                  MORE DETAILS&nbsp;
                  <img
                    src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/section-four-icon.png"}
                    alt="expand-circle-down"
                    border="0"
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "inline",
                    }}
                  />
                </Link>
              </Col>
              <Col lg={12} xl={12} xxl={12} className="four-rightsection">
                <div className="learn-img">
                  <img
                    src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/section-four-original-edited.png"}
                    alt="Learning"
                    width="550px"
                    height="auto"
                    style={{ objectFit: "cover", marginLeft: "-85px" }}
                  />
                </div>
              </Col>
            </Row>
          </section>
          <section className="sectionFive">
            <Row>
              <Col lg={10} xl={10} xxl={8} className="subSectionLeft">
                <div className="formCard">
                  <label className="formTitle">Send Message</label>
                  <form className="form">
                    <input name="firstName" placeholder="Full name" className="formInput" />
                    <input name="lastName" placeholder="Email address" className="formInput" />
                    <textarea
                      cols="2"
                      rows="50"
                      name="message"
                      placeholder="Message"
                      className="formInput messageInput"
                    />
                    <button type="submit">SEND</button>
                  </form>
                </div>
              </Col>
              <Col lg={12} xl={12} xxl={12} className="subSectionRight">
                <h3>
                  Get a <span style={{ color: "#FFAC0C" }}>FREE</span> class
                </h3>
                <p>
                  Unlimited access to 7,000+ world-class courses, hands-on projects, and job-ready certificate programs,
                  for one all-inclusive subscription price
                </p>
                <div className="bgImgContainer">
                  <img
                    src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/section-five.png"}
                    alt="bubble"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return { categories: state.categories, courses: state.courses };
};
export default connect(mapStateToProps, { list, getCategories })(Home);
