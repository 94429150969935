import React from "react";
import VideoPlayer from "./VideoPlayer";
const VideoDetail = ({ video, subscribed, course }) => {
  if (!video) {
    return <div>Loading...</div>;
  }
  const style = {
    backgroundColor: "blue",
  };
  const this_video_chapter = course.chapter_data.find(
    (chapter) => video.chapter_id == chapter.id
  );

  return (
    <div>
      <div className="ui embed" style={style}>
        <VideoPlayer
          subscribed={subscribed}
          video={video}
          this_video_chapter={this_video_chapter}
        />
      </div>
      <div className="ui segment">
        <h4 className="ui header">{video.name}</h4>
        <p>{video.description}</p>
      </div>
    </div>
  );
};

export default VideoDetail;
