import { Row, Col, Divider } from 'antd';
import React from 'react';
import Layout from '../../components/Layout';
import { Content } from 'antd/lib/layout/layout';
import Sidebar from '../../components/Sidebar/Sidebar';
import { connect } from 'react-redux';
import { subscriptionDetail } from '../../store/subscription/subscription.actions';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';

class Subscription extends React.Component {
    componentDidMount() {
        var pageUrl = window.location.pathname;
        this.props.subscriptionDetail(pageUrl.split('/').pop());
    }
    render() {
        const courses = this.props.Subscriptions.detail;
        const user = this.props.user.user;
        let total = 0;
        let purchaseInfo = '';
        
        if (typeof courses !== 'string' && Object.keys(courses).length > 0) {
            purchaseInfo = courses.body.purchase_details;
        }

        return (
            <Layout>
                <Row>
                    <Col span={6}><Sidebar/></Col>
                    <Col span={18}>
                        <Content>
                            {
                                typeof courses !== 'string' && Object.keys(courses).length > 0 ? 
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Title level={2}>Subscription Details</Title>
                                            <Text>Subscription #{purchaseInfo.purchase_id} was placed on {purchaseInfo.Subscription_date} and is currently {purchaseInfo.status? purchaseInfo.status: 'PROCESSING'}</Text>
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Row>
                                        <Col span={12}>
                                            <Title level={4}>course</Title>
                                        </Col>
                                        <Col span={12}>
                                        <Title level={4}>Total</Title>
                                        </Col>
                                    </Row>
                                    {
                                        courses.body.Subscriptioned_courses.map((course,i) => {
                                            total += course.price; 
                                            return (<Row key={i}>
                                                <Col span={12}>
                                                    {course.name}
                                                    {course.description}
                                                    {course.qty}
                                                </Col>
                                                <Col span={12}>
                                                    {course.price}
                                                </Col>
                                            </Row>)
                                        })
                                    }
                                    <Row justify="start" style={{paddingTop: "20px" }}>
                                        <Col span={12}>
                                        <Text>Sub-Total : { purchaseInfo.amount}</Text><br/>
                                        <Text>Shipping Total : { (purchaseInfo.shipping_cost)}</Text><br/>
                                        <Text strong>Grand Total : { purchaseInfo.amount+purchaseInfo.shipping_cost}</Text>
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Row>
                                        <Col span={24}>
                                            <Title level={2}>Billing Details</Title>
                                            <Paragraph>
                                                {purchaseInfo.address}
                                            </Paragraph>
                                            <Paragraph>
                                            {user.email} <br/> {user.phone? user.phone: null}
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                </> : <Text>No data found</Text>
                            }
                        </Content>
                    </Col>
                </Row>
            </Layout>
        )
    }
}
const mapStateToProps = (state) => {
    return { Subscriptions: state.subscriptions , user: state.user };
}

export default connect(
    mapStateToProps,
    { subscriptionDetail }
)(Subscription);