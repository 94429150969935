import React, { useState } from "react";
import { Row, Col, Card, Rate } from "antd";
import { NO_IMAGE } from "../../constants/DefaultLinks";
import Modal from "../Modal/Modal";
import "./CustomCard.css";
import { useSelector } from "react-redux";
const Text = ({ message = "", charCount = 0 }) => {
  return (
    <>
      {Number(charCount) === message.length ? (
        <p>{message}</p>
      ) : (
        <p>{message.slice(0, charCount).concat("...")}</p>
      )}
    </>
  );
};

const CustomCard = ({ course, cart, addToCart, handleModal }) => {
  const { name, price, thumbnail, id, slug, description, orprice, sale_name } = course;
  const featured_thumbnail = thumbnail ? thumbnail : NO_IMAGE;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const subscriptions = useSelector((state) => state.subscriptions.body);
  const handleAddToCart = (event) => {
    event.preventDefault();
    addToCart(course);
    setIsModalOpen(false);
  };

  const handleCheckout = (event) => {
    event.preventDefault();
  };

  const handleModalClose = (event) => {
    event.preventDefault();
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen ? (
        <Modal
          handleAddToCart={handleAddToCart}
          handleCheckout={handleCheckout}
          handleModalClose={handleModalClose}
        />
      ) : (
        <></>
      )}

      <Card
        className="customCard"
        hoverable
        cover={
          <img
            alt="course img"
            src={featured_thumbnail}
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "auto",
              display: "block",
            }}
          />
        }
      >
        <h2>{name}</h2>
        <h3>Software</h3>
        <Text message={description} charCount={80} />
        {/* <p>Lorem ipsum dolor sit amet. Est explicabo quos qui nemo doloremque et voluptatem</p> */}
        <div className="sub-container-three">
          <Row>
            <Col lg={16} xl={12} xxl={12} style={{ marginBottom: "5px" }}>
              <Row>
                <Col span={24}>
                  <label>4.0</label>
                  <Rate disabled defaultValue={4} />
                </Col>
                <Col span={24}>
                  <span className="course-price">{ sale_name == null ? price : 
                  <>
                  <div>{sale_name}</div>
                  <span>Sale Price $ {price} </span> 
                  </>
                   }</span>
                </Col>
              </Row>
            </Col>
            <Col
              lg={8}
              xl={12}
              xxl={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "end",
                paddingTop: "10px",
              }}
            >
              {subscriptions.length !== 0 ? (
                subscriptions.find((subscription) => {
                  return subscription.course_id === course.id;
                }) ? (
                  <label className="addedToCart">ENROLLED</label>
                ) : cart.find((item) => item.id === id) ? (
                  <label className="addedToCart">ADDED TO CART</label>
                ) : (
                  <button
                    className="buyBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsModalOpen(!isModalOpen);
                    }}
                  >
                    BUY
                  </button>
                )
              ) : cart.find((item) => item.id === id) ? (
                <label className="addedToCart">ADDED TO CART</label>
              ) : (
                <button
                  className="buyBtn"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsModalOpen(!isModalOpen);
                  }}
                >
                  BUY
                </button>
              )}
            </Col>
          </Row>
        </div>

        {/* <button
        className="cardAction"
        onClick={(event) => {
          event.preventDefault();
          addToCart(course);
        }}
      >
        {cart.find((item) => {
          return item.id === id;
        })
          ? "Remove From Cart"
          : "Add to Cart"}
      </button> */}
      </Card>
    </>
  );
};

export default CustomCard;
