import React, { Component } from "react";
import Layout from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";

import "./ThankYou.css";

class ThankYou extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <>
                <Layout>
                    <Content>
                        <div className="thank-you">
                            <h3>Thank You for registering for Webinar</h3>
                            <h6>Please check your email for webinar link</h6>
                        </div>
                    </Content>
                </Layout>
            </>
        );
    }
}

export default ThankYou;
