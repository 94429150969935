export const CREATE_USER = 'CREATE_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILED = 'CURRENT_USER_FAILED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';