import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute = ({ isSignedIn, children }) => {
    let location = useLocation();

    if (!isSignedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
};

export const GuestRoute = ({ isSignedIn, children }) => {
    let location = useLocation();

    if (isSignedIn) {
        return <Navigate to={location.state ? location.state.from.pathname : '/'} state={{ from: location }} replace />;
    }
    return children;
};

