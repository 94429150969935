import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Checkbox, Row, Col } from "antd";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { Link } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { LOGIN_USER_FAILED, LOGIN_USER_SUCCESS, VERIFY_USER } from "../../store/user/user.types";
import "./Login.css";
class LoginForm extends React.Component {
  onSubmit = (values) => {
    if (values.code) {
      this.props.verify(values.code);
    } else {
      this.props.onSubmit(values);
    }
  };

  render() {
    const { user } = this.props;
    return (
      <>
        {user.action === LOGIN_USER_SUCCESS || user.action === LOGIN_USER_FAILED || user.action === VERIFY_USER ? (
          <Notifications user={user} />
        ) : null}
       
        <Form onFinish={this.props.handleSubmit(this.onSubmit)} className="loginForm">
          <Row>
            <Col span={24}>
              <Field name="email" placeholder="Email" component={createField} />
            </Col>
            <Col span={24}>
              <Field name="password" placeholder="Password" component={createField} />
            </Col>
            <Col span={24}>
              <div className="formFeature">
                <Checkbox className="rememberMeCheckbox">Remember me</Checkbox>
                <Link to="/forgot-password">Forgot password</Link>
              </div>
            </Col>
            <Col span={24}>
              <button className="primaryBtn loginBtn" type="submit">
                SIGN IN
              </button>
              {(user.action === LOGIN_USER_FAILED && user.user.codeSent) ||
              (user.action === VERIFY_USER && !user.user.status) ? (
                <div>
                  <Title level={2}>Check email for verification code</Title>
                  <Field name="code" placeholder="Verification code" component={createField} />
                  <button className="primaryBtn loginBtn" type="submit">
                    Verify
                  </button>
                </div>
              ) : null}
            </Col>
            <Col span={24} style={{ padding: "1.5rem 0" }}>
              <p className="formFooter">
                Don't have an account <Link to="/register">Sign-up</Link>
              </p>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "This field is Required";
  }
  if (!values.password) {
    errors.password = "This field is Required";
  }
  return errors;
};

export default reduxForm({
  form: "LoginForm",
  validate,
})(LoginForm);
