export const CC_CREATE_USER = 'CC_CREATE_USER';
export const CC_VERIFY_USER = 'CC_VERIFY_USER';
export const CC_LOGIN_USER_SUCCESS = 'CC_LOGIN_USER_SUCCESS';
export const CC_LOGIN_USER_FAILED = 'CC_LOGIN_USER_FAILED';
export const CC_LOGOUT_USER = 'CC_LOGOUT_USER';
export const CC_CURRENT_USER_SUCCESS = 'CC_CURRENT_USER_SUCCESS';
export const CC_CURRENT_USER_FAILED = 'CC_CURRENT_USER_FAILED';
export const CC_FORGOT_PASSWORD = 'CC_FORGOT_PASSWORD';
export const CC_RESET_PASSWORD = 'CC_RESET_PASSWORD';
export const CC_UPDATE_USER_SUCCESS = 'CC_UPDATE_USER_SUCCESS';
export const CC_CHANGE_PASSWORD = 'CC_CHANGE_PASSWORD';
export const CC_ADDED_TO_GROUP = 'CC_ADDED_TO_GROUP';