import React, { Component } from "react";
import { DetailView } from "../../store/course/course.actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, Rate, Spin } from "antd";
import "./CourseInFo.css";
import { AddToCart } from "../../store/course/course.actions";
import { ChangeQuantity } from "../../store/course/course.actions";
import { SubscribeCourse } from "../../store/course/course.actions";
import { userBasedsubscriptions } from "../../store/subscription/subscription.actions";
import { Link } from "react-router-dom";
import { error } from "../../components/Alerts";
import CourseAdditionDetails from "../../components/CourseAdditionalDetails/CourseAdditionDetails";
import StripeCheckout from "react-stripe-checkout";
class CourseInFo extends Component {
  componentDidMount() {
    this.props.DetailView(this.props.id);
    this.props.username && this.props.userBasedsubscriptions();
  }

  render() {
    if (this.props.subscriptions.body == [] || !this.props.subscriptions) {
      this.props.username && this.props.userBasedsubscriptions();
    }
    const course = this.props.details;
    const onToken=(token)=>{
      this.props.SubscribeCourse(course,token);
    };
    // const course = Object.values(this.props.details);
    // const total = coursesList.reduce((acc, current) => acc + current.price, 0);
    return (
      <>
        {this.props.details ? (
          <div className="courseDetailsPage">
            {/* Course Details Section Starts */}
            <section className="course-details">
              <Row>
                <Col span={16}>
                  <div className="course-info">
                    <Breadcrumb separator=">">
                      <Breadcrumb.Item className="course-breadcrumb">
                        Courses
                      </Breadcrumb.Item>
                      <Breadcrumb.Item className="course-breadcrumb" href="">
                        Software
                      </Breadcrumb.Item>
                      <Breadcrumb.Item className="course-breadcrumb">
                        {this.props?.details?.name}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <h3>{this.props?.details?.name}</h3>
                    <h6>Software</h6>
                    <p>{this.props.details.description}</p>
                    <p className="course-rating">4.0</p>{" "}
                    <Rate disabled defaultValue={4} />
                    <h5>${this.props.details.price}</h5>
                    <div className="course-details-actions">
                      <Link
                        to={`/course/${this.props.details.id}`}
                        className="courseContentBtn"
                        >
                        COURSE CONTENT
                      </Link>

                      {this.props.subscriptions &&
                      this.props.subscriptions.find((course) => {
                        return course.course_id === this.props.details.id;
                      }) ? (
                        <Link
                        to={`/course/${this.props.details.id}`}
                        className="courseBuyBtn"
                        style={{ marginLeft: 0 }}
                        >
                          ENROLLED
                        </Link>
                      ) : (
                        <span
                        onClick={() => {
                          if (!this.props.username) {
                            error(
                              "Subscribe Failed !. You Must Register and Login to Subscribe",
                              11000
                              );
                            }                                
                            }}
                            > 
                             <StripeCheckout
                                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                                name="Easy Learn" 
                                description="Learn the Future"
                                image=""
                                amount={course.price*100}
                                allowRememberMe
                                token={onToken}
                                opened={this.onOpened}
                                closed={this.onClosed}
                              >
                              <Link to={"#"} className="courseBuyBtn">BUY</Link>
                              </StripeCheckout>
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="svg-col">
                    <svg
                      width="272"
                      height="272"
                      viewBox="0 0 272 272"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5" clip-path="url(#clip0_168_281)">
                        <path
                          d="M136 272C169.071 272 199.385 260.197 222.954 240.566L194.277 198.535C178.991 212.788 158.498 221.538 136 221.538C113.502 221.538 92.3446 212.492 76.9846 197.821L48.2708 239.901C71.9507 259.914 102.572 271.988 136 271.988V272Z"
                          fill="white"
                          fill-opacity="0.1"
                        />
                        <path
                          d="M269.649 110.769C257.821 47.7169 202.486 0 136 0C69.5138 0 14.1785 47.7169 2.35077 110.769C0.812307 118.942 0 127.372 0 136C0 144.628 0.812307 153.058 2.35077 161.231H269.649C271.188 153.058 272 144.628 272 136C272 127.372 271.188 118.942 269.649 110.769ZM136 50.4615C174.388 50.4615 206.941 75.8769 217.735 110.769H54.2646C65.0584 75.8769 97.6123 50.4615 136 50.4615Z"
                          fill="white"
                          fill-opacity="0.1"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_168_281">
                          <rect width="272" height="272" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Col>
              </Row>
            </section>
            <section>
              <CourseAdditionDetails check='true' />
            </section>
          </div>
        ) : (
          <Spin />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    details: state.detail.detail,
    cart: state.mycart,
    subscriptions: state.subscriptions.body,
    username: state.user.user.username,
  };
};
export default connect(mapStateToProps, {
  DetailView,
  AddToCart,
  ChangeQuantity,
  SubscribeCourse,
  userBasedsubscriptions,
})(CourseInFo);
