import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SidebarRoutes from '../../constants/sidebar';

const Sidebar = () => {
    const location = useLocation();
    const isSignedIn = useSelector(state => state.user.isSignedIn);
   

    const renderMenu = (menuItem, i) => {
        if(menuItem.HideOnMenu){
            return null
        };
        if((menuItem.isSignedIn === isSignedIn || menuItem.isSignedIn === null) && menuItem.name !== null) {
            return (
                <Menu.Item key={i} className={location.pathname === menuItem.path ? 'ant-menu-item-active': ''}>
                    <Link to={menuItem.path}>{menuItem.icon }  { menuItem.name}</Link>
                </Menu.Item>
            );
        }
    }
    return (
        <>
            
            <Menu
                mode="inline"
                theme="dark"
            >
                {
                SidebarRoutes.map((route, i) => {
                    return (
                        renderMenu(route,i)
                    )
                })
                }
            </Menu>
        </>
    );
};

export default Sidebar;