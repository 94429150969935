import React, { Component } from "react";
import { DetailView} from "../../../store/course/course.actions";
import { connect } from "react-redux";
import {  Row, Col,  } from "antd";
import { AddToCart } from "../../../store/course/course.actions";
import Layout from "../../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import ItemQuantity from "../../../components/Inputs/ItemQuantity";
import { ChangeQuantity , SubscribeCourse  } from "../../../store/course/course.actions";
import "./styles.css";
import CustomCartCard from "../../../components/CustomCartCard/CustomCartCard";
import course from "../../SingleCourse/course";
import StripeCheckout from "react-stripe-checkout";

class MyCart extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    this.state = { NumberOfItems: Object.values(this.props.myItems) };
    const coursesList = Object.values(this.props.myItems);
    const total = coursesList.reduce((acc, current) => acc + current.price, 0);
    const onToken=(token)=>{
      coursesList.map((course)=>{
      this.props.SubscribeCourse(course,token)
      })
     
    };
    
    // console.log(this.state);
    // return (
    //   <>
    //     <Layout>
    //       <Content>
    //         <div className="wrapper">
    //           <div className="links p-0">
    //             <ul>
    //               <li data-view="list-view" className="li-list active">
    //                 CART
    //               </li>
    //               {/* <li data-view="grid-view" className="li-grid">
    //                 <i className="fas fa-th-large"></i>
    //                 Grid View</li> */}
    //             </ul>
    //           </div>
    //           <div>
    //             {/* <div className="view_main">
    //           <div className="view_wrap list-view" style={{ display: "block" }}> */}
    //             <Row gutter={[0, 24]}>
    //               {coursesList.map((course) => {
    //                 return (
    //                   <Col span="24" classNv.REACT_APP_STRIPE_KEY}
                        // name="Easy Learn" 
                        // description="Learn the Future"
                        // image=""
    //                           />
    //                         </Badge.Ribbon>
    //                       </Col>
    //                       <Col sm={24} xl={18}>
    //                         <Row justify="center" align="middle">
    //                           <Col span="20">
    //                             <Link to={`/SingleCourse/${course.slug}`}>
    //                               <p className="title">{course.name}</p>
    //                             </Link>
    //                           </Col>
    //                           <Col span="4">
    //                             <div className="btn text-align-center">
    //                               <Button
    //                                 onClick={() => this.props.AddToCart(course)}
    //                                 style={{ width: "50%", border: "none" }}
    //                               >Subsgg :
    //                                     null
    //                                     } */}
    //                    onst onToken = (token) => {
//     SubscribeCourse(course,token);
//     console.log(this.state);
// }</Col>
    //                 );
    //               })}
    //             </Row>
    //             {/* </div>
    //     </div> */}
    //           </div>
    //           <div className="links" style={{ position: "relative", float: "right", background: "none" }}>
    //             <Row>
    //               <Col></Col>
    //             </Row>
    //           </div>
    //         </div>
    //       </Content>
    //     </Layout>
    //   </>
    // );

    return (
      <Layout> 
        <Content>
          <div className="cartPage">
            <div className="cartContainer">
              {coursesList.length > 0 && (
                <>
                  <Row gutter={[24, 24]} style={{ width: "65%", paddingBottom: "2rem" }}>
                    <Col lg={24}>
                      <h2 className="cartTitle">Your cart</h2>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ width: "65%", paddingBottom: "3.5rem" }}>
                    {coursesList.map((course) => (
                      <Col xl={24} lg={24} key={course?.id}>
                        <CustomCartCard course={course} addToCart={this.props.AddToCart} />
                      </Col>
                    ))}
                    
                    
                  </Row>
                  <Row gutter={[24, 24]} style={{ width: "65%", paddingBottom: "2rem" }}>
                    <Col lg={24}>
                     
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ width: "65%", paddingBottom: "1rem" }}>
                    <Col lg={24} className="cartContainerFooter">
                      <label>Total:</label>&nbsp;
                      <span>$ {total}</span>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ width: "65%", paddingBottom: "2rem" }}>
                    <Col lg={24} className="checkout">

                    <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                        name="Easy Learn" 
                        description="Learn the Future"
                        image=""
                        amount={total*100}
                        allowRememberMe
                        token={onToken}
                        opened={this.onOpened}
                        closed={this.onClosed}
                      >
                      <button>CHECKOUT</button>
                      </StripeCheckout>
                    </Col>
                  </Row>
                </>
              )}

              {coursesList.length === 0 && (
                <div className="my-cart">
                  <img
                    src="https://i.ibb.co/PMPBv8h/Empty-cart-graphics.png"
                    alt="Empty-cart-graphics"
                    class="img-center"
                  ></img>
                  <h1>Empty cart</h1>
                  <p>Looks like you haven’t made your choice yet...</p>
                  <Link to={`/courses`}>
                  <button className="cart-course">GO TO COURSES</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return { myItems: state.mycart };
};
export default connect(mapStateToProps, { AddToCart, ChangeQuantity ,SubscribeCourse })(MyCart);
