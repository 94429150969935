import React from 'react'
import { Card, Image } from 'antd';
import { Link } from 'react-router-dom';
const { Meta } = Card;

const Popup = (props) => {
  return (
    <div>
        <Card
    hoverable
    // style={{
    //   width: 240,
    // }}
    cover={<Image src="" />}
  >
    <Meta title={props.username} description={<><p>{props.msg}</p><Link to={props.url}>Proceed Here</Link></>} />
  </Card>
    </div>
  )
}

export default Popup