import React from 'react';
import { Button, Card, Col, Image, Row,Select } from 'antd';

import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { NO_IMAGE } from '../../constants/DefaultLinks';



function Listing({items,mycart,AddToCart}) {
    const renderList=()=> {
        const chunkedItems = _.chunk(Object.values(items), 3);
        
        return chunkedItems.map( (courses,i) => {
            return (<Row gutter={[24, 24]}>
            { 
                courses.map((course,index) => {
                    if( course !== null) {
                        const {
                            name,
                            price,
                            thumbnail,
                            id,
                            slug
                        } = course;
                        const featured_thumbnail = thumbnail? thumbnail : NO_IMAGE;
    
                        return (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} index={index}>
                <Link to={`/SingleCourse/${slug}`}>
              <Card
                className="course-card"
                hoverable
                cover={
                  featured_thumbnail ? (
                    <>
                      <Image preview={false} alt={name} src={featured_thumbnail} />
                    </>
                  ) : null
                }
              >
                  {name}
                  <Row gutter={[0, 10]}>
                      <Col span={24}>
                        <Text className="card-title" strong> {name}</Text>
                      </Col>
                      <Col span={24}>
                        <Text type="secondary" className="card-subtitle">{`$${price}`}</Text>
                        {/* {on_sale && (
                                          <Text style={{ marginLeft: 15 }}>{`$${sale_price}`}</Text>
                                          )} */}
                      </Col>
                    <Col span={24}>
                      <Button onClick={(event) =>  {event.preventDefault(); AddToCart(course)}}>
                        {mycart.find((item) => {
                          return item.id === id;
                        })
                          ? "Remove From Cart"
                          : "Add to Cart"}
                      </Button>
                    </Col>
                  </Row>
              </Card>
                </Link>
            </Col>
          );
                    }
                })
            }
            </Row>)
        });
    }
  return (
    <>{renderList()}</>
  )
}

export default Listing