import React from 'react';
import Layout from '../../components/Layout';
import { Button, Card, Col, Image, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { connect } from 'react-redux';
import { categoryBased } from '../../store/course/course.actions';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { AddToCart } from '../../store/course/course.actions';
import { NO_IMAGE } from '../../constants/DefaultLinks';
import {list} from '../../store/course/course.actions';
import CardsLayout from '../../components/cardsLayout';
class Category extends React.Component {
    componentDidMount() {
        this.props.list('all')
    }
    renderList() {
        const chunkedItems = _.chunk(this.props.items, 3);
        return chunkedItems.map( (courses,i) => {
            return (<Row key={i}>
            { 
                courses.map((course,index) => {
                    if( course !== null) {
                        const {
                            name,
                            price,
                            img_url,
                            id
                        } = course;
                        const featured_img_url = img_url? img_url : NO_IMAGE;
    
                        return (
                            <Col xl={8} lg={8} md={8} sm={12} xs={24} className="centered-col" key={index}>
                                <Card hoverable cover={
                                    featured_img_url ? <Image width={200} height={200} alt={name} src={featured_img_url} /> : null
                                }
                                >
                                    <Row>
                                        <Text style={{ textAlign: 'center' }} strong>
                                            <Link to={`/SingleCourse/${id}`}>{name}</Link> 
                                        </Text>
                                    </Row>
                                    <Row>
                                        <Text type="secondary">
                                        {`$${price}`}
                                        </Text>
                                        
                                    </Row>
                                    <Button onClick={()=>this.props.AddToCart(course)}>
                                        {(this.props.cart.find((item)=>{return item.id===id}))?
                                        "Remove From Cart"
                                        :
                                        "Add to Cart"
                                        }
                                    </Button>
                                </Card>
                            </Col>
                        );
                    }
                })
            }
            </Row>)
        });
    }
    render() {
        if (this.props.items[0] === null) {
            return <div></div>;
        }
        return (
            <Layout>
                <Content>
                    <Title level={2}>categories</Title>
                    <Space size={[8, 16]} wrap>
                        <Row gutter={[24, 24]}>
                            {
                                this.props.items && Object.values(this.props.items).map((cardsdata) => {
                                    return(<CardsLayout cardsData={cardsdata}/>)
                                })
                            }  
 
                        </Row>
                      </Space>
                
                
                </Content>
            </Layout>
        );
    }
};

const mapStateToProps = state => {
    return { 
        items: state.categories
    };
};

export default connect(
    mapStateToProps,
    { categoryBased,AddToCart,list}
)(Category);