import { SUBMITTED, GET_QUERIES, QUERY_REPLY_ADDED, IMAGE_UPLOADED } from "./queryboard.types";
import { CLIENT_BASE_URL_AXIOS } from '../../services/apis';

export const submitQuery = (values, courseid) => async (dispatch, getState) => {
   var response
    const body = JSON.stringify(
        {
            image_data: values.base64,
            course_id: courseid,
            user_id: getState().user.user.username,
            title: values.title,
            description: values.description
        });
    response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_QUERYBOARD_ADD_QUERY_REQUEST_ENDPOINT, body);
    dispatch({ type: SUBMITTED, payload: SUBMITTED });
};


export const getQueries = (id) => async (dispatch, getState) => {
    var response;
    response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_QUERYBOARD_GET_QUERY_REQUEST_ENDPOINT + '/' + id);
    dispatch({ type: GET_QUERIES, payload: response });
};
export const replyQueries = (id,description,img) => async (dispatch, getState) => {
    var response;
    var imgdata;
    img ? imgdata=img : imgdata=''
    const body = JSON.stringify(
        {
            query_id: id,
            user_id: getState().user.user.username,
            description: description,
            image_data : imgdata
        });
    response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_QUERYBOARD_ADD_REPLY_ENDPOINT,body);

};
export const imageUpload = (value) => async (dispatch, getState) => {
    

    dispatch({ type: IMAGE_UPLOADED, payload: value });
};

