import React from "react";
import { Avatar, Badge } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function CustomCartIcon() {
  const no_if_items = useSelector(state=> state.mycart?Object.values(state.mycart): 0)
  return (
    <Link to={`/mycart`}>
    <div className="cartIcon">
      <Badge count={no_if_items.length} color="#ffac0c" size="medium">
        <Avatar
          shape="square"
          size="small"
          src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/cart-icon.png"}
          />
      </Badge>
    </div>
    </Link>
  );
}

export default CustomCartIcon;
