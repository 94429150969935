import React, { Component } from 'react'
import { connect } from 'react-redux'
import Listing from '../../components/List'
import {list} from '../../store/course/course.actions'
import { AddToCart } from '../../store/course/course.actions'
import Title from 'antd/lib/typography/Title';
 class ListCategoryCourses extends Component {
    componentDidMount(){
        this.props.list(this.props.category)
    }
  render() {
    return (
      <>
      <Title level={2}>{this.props.category}</Title>
      {this.props.courses && <Listing mycart={Object.values(this.props.mycart)} AddToCart={this.props.AddToCart} items={this.props.courses}/>}</>
    )
  }
}
const mapStateToProps=(state)=>{
    return {courses: state.courses,mycart:state.mycart}

}
export default connect(mapStateToProps,{list,AddToCart})(ListCategoryCourses)