import { Menu, Row, Col, Dropdown, Avatar } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../constants/routes";
import { logOut } from "../../store/user/user.actions";
import "./index.css";
import ProfileMenu from "./ProfileMenu";
const HeaderComp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  const email = useSelector((state) => state.user.user.email);
  let lastIndex =0
  const renderMenu = (menuItem, i) => {
    if (menuItem.HideOnMenu) {
      return null;
    }
    if (
      (menuItem.isSignedIn === isSignedIn || menuItem.isSignedIn === null) &&
      menuItem.name !== null
    ) {
      return (
        <>
          {menuItem.icon ? (
            menuItem.icon
          ) : (
            <Menu.Item key={menuItem.name} className="m-0">
              <Link to={menuItem.path} className="navMenu">
                {menuItem.name}
              </Link>
            </Menu.Item>
          )}
        </>
      );
      return (
        <Menu.Item
          key={i}
          className={
            ["mycart", "Login"].includes(menuItem.name)
              ? "ml-auto"
              : "" ||
                location.pathname === menuItem.path ||
                menuItem.child.indexOf(location.pathname) !== -1
              ? "ant-menu-item-active"
              : ""
          }
        >
          <Link to={menuItem.path}>
            {menuItem.icon ? menuItem.icon : menuItem.name}
          </Link>
        </Menu.Item>
      );
    }
  };

  const profileBar = () => {
    return (
      <Menu className="HeaderProfileMenu">
        <Menu.Item className="profileBasic" disabled>
          <Avatar
            size={30}
            src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/avatar.png"}
            className="profileImg"
          />
          <div>
            {/* <h3>James Hardley</h3> */}
            <h4>{email}</h4>
          </div>
        </Menu.Item>
        <hr
          style={{
            borderBottom: "1px solid #F1F0F0",
            marginBottom: "16px",
            borderTop: 0,
          }}
        />
        {ProfileMenu()}
        <Menu.Item
          className="profileMenuItem"
          icon={
            <img
              src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/logout-icon.png"}
              style={{ width: "10px" }}
            />
          }
        >
          <Link to="/" onClick={() => dispatch(logOut())} className="navMenu">
            Log out
          </Link>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <header>
      <Row className="menuHeader">
        <Col span={6} className="companyDetails">
          <Link to="/">
            <div className="imgContainer">
              <img
                src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/company-logo.png"}
                width="100%"
                height="100%"
                style={{ display: "block", objectFit: "cover" }}
              />
            </div>
            <label>Easy Learn</label>
          </Link>
        </Col>
        <Col span={4}></Col>
        <Col span={14} className="navRightSection">
          <Menu className="navContainer" defaultSelectedKeys={["Home"]}>
            {routes.map((route, i) => {
              lastIndex = i;
              return renderMenu(route, i);
            })}
          </Menu>

          {isSignedIn ? (
            <>
              <div className="header-profile-container">
                <Dropdown overlay={profileBar} placement="bottomLeft">
                  <Avatar
                    size={40}
                    src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/avatar.png"}
                    className="profileImg"
                  />
                </Dropdown>
              </div>
            </>
          ) : (
            <Link
              to="/register"
              className="primaryBtn"
              style={{ width: "105px", height: "42px" }}
            >
              SIGN UP
            </Link>
          )}
        </Col>
      </Row>
    </header>
  );
};

export default HeaderComp;
