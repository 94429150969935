import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Queryboard from "./QueryBoard";
import { useDispatch } from 'react-redux'
import { List, Image, Space, Avatar, Comment, Divider, Button, Form, Modal } from "antd";
import { connect } from "react-redux";
import { getQueries, replyQueries } from "../../store/queryboard/queryboard.actions";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import ReplyToQuery from "./ReplyToQuery";

let qid;
function QueryIndex(props) {
    const { id } = useParams();
    const [queries, setQueries] = useState(false);
    const [comp, setComp] = useState('query')
    const [cnt, setCnt] = useState(3)
    const [clickedAskQuestion, setClickedAskQuestion] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
  
    const dispatch = useDispatch();
    const { getQueries } = props
    useEffect(() => {
        if (queries) {
            getQueries(id)
            setQueries(false)
        } else {
            getQueries(id)
        }

    }, [queries]);

    const onsubmit = () =>{
        setComp('query')
        
    }
    const addReply = () =>{
        setQueries(true)
    }

    const loadMore = () =>{
        <Button onClick={onLoadMore}>loading more</Button>
    }
    const onLoadMore = () =>{
       setCnt(cnt+3)
    }
    const renderComp = () =>{
        setComp('reply')
        setClickedAskQuestion(true)
       
    }
    const showQueryForm = () =>{
        console.log('clicked')
        setComp('query')
        setClickedAskQuestion(false)
       
    }

    const handleOk = () => {
        setIsModalOpen(false)
       
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (

        <>
         
            {comp == 'query' ? 
                       <>
                       <Content>
                        <List
                            header="All QUESTIONS IN THIS COURSE"
                            itemLayout="horizontal"
                            dataSource={Object.values(_.reverse(props.query)).slice(0,cnt)}
                            // pagination={true}
                            loadMore = {loadMore}
                            
                            renderItem={(item) => (
                                <List.Item onClick={() =>{
                                    qid = item.id
                                    setComp('reply')
                                    setClickedAskQuestion(false)
                                }
                                    
                                   
                                }>
                                    <List.Item.Meta
                                        avatar={<Space size={12}>
                                            <Image src={item.attachment} width={100} height={100}
                                                placeholder={
                                                    <Image preview={false} src={item.attachment} width={100} height={100} />
                                                } />
                                        </Space>}
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                                
                            )}
                        />
                    </Content>
                    <Form.Item>
                    <Button onClick={onLoadMore}>SEE MORE</Button>
                    </Form.Item>
                    <Form.Item>
                    <Button onClick={renderComp}
                    style={{
                        background: "white",
                        color: "#597B65",
                        padding: "10px",
                        height: "50px",
                        border: "1px solid #597B65",
                        borderRadius : "20px"
                      }}
                    >ASK YOUR QUESTION</Button>
                    </Form.Item>
                    
                    </>
            
            : 
            <>
            <Divider/>
            {clickedAskQuestion ? 
            <>
            <Queryboard id={id} setQuery={setQueries} showQueryForm={showQueryForm} setIsModalOpen = {setIsModalOpen}/>
            <Modal title="" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={[
              <Button key="Ok" onClick={handleOk}>
                OK
              </Button>]}
              width={310}
            >
                      <div className='modal-container'>
                 <Divider/>
              <Divider/>
              <h3>Your Query is Submitted Successfully</h3>
              <Divider/>
              <Divider/>
                      </div>
            </Modal> 
            </>
            : 
            <ReplyToQuery id={qid} OnSubmit = {onsubmit} setQuery={addReply} query={props.query}/> 
            }
            
            </>
            }




           
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        query: state.query
    };
};

export default connect(mapStateToProps, {
    getQueries,
})(QueryIndex);
