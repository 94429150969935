import React, { useState } from 'react';
import { Avatar, Button, Comment, Form, Input, List, Divider, Upload, Image } from 'antd';
import { LeftOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import RepliesAdded from './RepliesAdded';
import { replyQueries, getQueries, imageUpload } from '../../store/queryboard/queryboard.actions';
import {useDispatch, useSelector} from 'react-redux'

const { TextArea } = Input;
let img;
const CommentList = ({ comments }) => (
 
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={(props) => <Comment {...props} />}
    />
  );


  const Editor = ({ onChange, onSubmit, submitting, value , onImageChange, form}) => (
    
    <>
    <Form
    form={form}
    >
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
          SUBMIT ANSWER
        </Button>
        <Upload
                            onChange={(e) => onImageChange(e)}
                            listType="picture"
                            name="image"
                            onRemove = { () => {console.log('removed')}}
                            
                        >
                            <Button icon={<UploadOutlined />}>Upload image</Button>
                        </Upload>

      </Form.Item>
      </Form>
    </>
  );

  const ReplyToQuery = (props) => {
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const [imgdata, setImage] = useState('')
  
    const [form] = Form.useForm();

    const dispatch = useDispatch()
    const uname = useSelector((state) => state.user.user.email)
    

    
    const handleSubmit = () => {
      if (!value) return;
      setSubmitting(true);
      
      dispatch(replyQueries(props.id,value,imgdata))
     
      setTimeout(() => {
        setSubmitting(false);
        setValue('');
        
        setComments([
          ...comments,
          {
            author: uname,
            // avatar: 'https://joeschmoe.io/api/v1/random',
            avatar: <Avatar size="small" icon={<UserOutlined />} />,
            content: <>
                    <p>{value}</p>
                     {imgdata ? 
                     <div className='replied-img'>
                     <Image width={300}src={imgdata} />
                     </div> : null}
                     </> 
                     
            // datetime: moment('2016-11-22').fromNow(),
          },
        ]);
      }, 1000);
      // form.resetFields()
      form.setFieldsValue({image:null})
      setImage('')
      props.setQuery()
    };
    const handleChange = (e) => {
      setValue(e.target.value);

    };
    const encodeImageFileAsURL = (element) => {
      console.log("e", element);
      var file = element.file.originFileObj;
      var reader = new FileReader();
      reader.onloadend = () => {
          console.log("RESULT", reader.result);
          setImage(reader.result)
      };
      const str = reader.readAsDataURL(file);
        console.log("str", str);
      
  };
    return (
      <>
        <Button type="text" onClick={() =>{
            props.OnSubmit()
        } }><LeftOutlined /> Back To All Questions</Button>
        <Divider dashed/>
        <RepliesAdded query={props.query} id={props.id}/>
      
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          content={
            <Editor
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
              onImageChange={encodeImageFileAsURL}
              form = {form}
             
            />
          }
        />

      </>
    );
  };
  export default ReplyToQuery;