import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import "./About.css";

const About = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Layout>
      <Content>
        <div className="about-us-page">
          <section className="about-us">
            <h6>About us</h6>
            <p>
            We'r an E-Lerning platform . We've been helping businesses sell smarter and faster since 2012. We're proud HubSpot Diamondand RollWorks Partners, and we pride ourselves on using the best tools to help our clients succeed. Our team is made up of smart and talented people that are passionate about creating marketing and sales results!
            </p>
            <p>
            We're rooted in sales. Our parent company, The Center for Sales Strategy (CSS), has been helping sales organizations turn talent into performance for almost 40 years. Unlike other marketing agencies, we're obsessed with ROI and we have the experience to deliver inbound sales results because we've done it ourselves...

We've been where you are. More than a decade ago, when we needed to grow and diversify how we generated new business at CSS, we turned to inbound marketing and found huge success after launching our sales strategy blog. Once we mastered the art of using thought leadership content for lead generation, we launched LeadG2 so we could help businesses do the exact same thing. 
            </p>
            <p>
            We're rooted in sales. Our parent company, The Center for Sales Strategy (CSS), has been helping sales organizations turn talent into performance for almost 40 years. Unlike other marketing agencies, we're obsessed with ROI and we have the experience to deliver inbound sales results because we've done it ourselves...

We've been where you are. More than a decade ago, when we needed to grow and diversify how we generated new business at CSS, we turned to inbound marketing and found huge success after launching our sales strategy blog. Once we mastered the art of using thought leadership content for lead generation, we launched LeadG2 so we could help businesses do the exact same thing. 
            </p>
          </section>
          <section className="our-clients">
            <p>Our Clients</p>
            <div className="client-logo">
              <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/pexels.jpg"} alt="Slack"></img>
              <img src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/pexels-mike-2.jpg"} alt="jaguar"></img>

            </div>
          </section>
        </div>
      </Content>
    </Layout>
  );
};

export default About;
