import React, { useEffect } from "react";
import { Tabs, Rate, List } from "antd";
import Layout from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";
import "./MyCourses.css";
import { userBasedsubscriptions } from "../../store/subscription/subscription.actions";
import { currentUser } from "../../store/user/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { list } from "../../store/course/course.actions";
import { Link } from "react-router-dom";

const Card = ({ data }) => {
  return (
    <Link to={`/SingleCourse/${data.slug}`}>
    <div className="my-course-card">
      <img src={data.thumbnail} alt="" />
      <div>
        <h2>{data.name}</h2>
        <h3>Software</h3>
        <p>{data.description}</p>
        <h4>50% Completed</h4>
        <Rate allowHalf defaultValue={2.5} />
        <h5>Leave rating</h5>
      </div>
    </div>
    </Link>
  );
};

const MyCourses = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptions.body);
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  const courses = useSelector((state) => state.courses);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(list("all"));
    dispatch(currentUser());
  }, []);
  return (
    <Layout>
      <Content
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#F8FAFF",
          width: "100%",
          marginTop: "90px",
        }}
      >
        <section className="my-courses-section-one" style={{ position: "relative" }}>
          <h2>My courses</h2>
          <img
            src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
            className="bg-img-one"
          />
          <img
            src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
            className="bg-img-two"
          />
          <img
            src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/courses-background.svg"}
            className="bg-img-three"
          />
        </section>

        <section className="my-courses-section-two">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Home" key="1">
              {courses && (
                <List
                  dataSource={Object.values(courses)}
                  pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                  }}
                  grid={{
                    gutter: 24,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 3,
                    xxl: 3,
                  }}
                  renderItem={(item) => (
                    <List.Item>
                      <Card data={item} />
                    </List.Item>
                  )}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Inprogress" key="2">
              
            </Tabs.TabPane>
            <Tabs.TabPane tab="Completed" key="3">
              
            </Tabs.TabPane>
          </Tabs>
        </section>

        {!subscriptions && (
          <section className="section-three">
            <div className="no-course">
              <img src="https://i.ibb.co/3NrcVsv/No-courses-graphics.png" alt="No Course" class="img-center"></img>
              <h1>No courses available</h1>
              <p>You have not subscribed to any course</p>
              <button className="cart-course">GO TO COURSES</button>
            </div>
          </section>
        )}
      </Content>
    </Layout>
  );
};

export default MyCourses;
