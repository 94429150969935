import { SettingOutlined, UserOutlined } from "@ant-design/icons";

const SidebarRoutes = [
    {
        path: '/account',
        name: "Profile",
        icon: <UserOutlined/>,
        isSignedIn: true
    },
    {
        path: '/change-password',
        name: "Password update",
        icon: <SettingOutlined/>,
        isSignedIn: true
    },
    {
        path: '/subscriptions',
        name: "Subscription History",
        icon: <SettingOutlined/>,
        isSignedIn: true
    }
]

export default SidebarRoutes;