import React, { useEffect } from "react";
import "./TermsCondition.css";
import { Content } from "antd/lib/layout/layout";
import Layout from "../../components/Layout";

const TermsCondition = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Layout>
      <Content>
        <section className="terms-condition">
          <h6 className="heading-6">Terms & conditions</h6>
          <div className="paragraph">
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Goods sold are non-refundable, non-exchangeable. All goods are checked with stringent quality assurance and so if the goods are faulty, it should be reported within 5 days of receipt. Tomal reserves the right to assess the condition of goods if there were damages , and if so, goods will not be exchanged. All installations of equipment are done by third party contractors and Tomal is indemnified against all third party liabilities or other liabilities arising from the installation of goods.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          <p>
          Other than content you own, which you may have opted to include on this Website, under these Terms, Tomal Global and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
          </p>
          </div>
        </section>
      </Content>
    </Layout>
  );
};

export default TermsCondition;
