import React, { Component } from 'react'
import Course from './Course';
import { FecthCourseContent } from '../../../store/course/course.actions';
import { connect } from 'react-redux';
import {userBasedsubscriptions} from '../../../store/subscription/subscription.actions'
import  {list} from '../../../store/course/course.actions'
class CourseWrap extends Component {
  constructor(props){
    super(props);
    // this.props.list('all');
}
  componentDidMount(){
    // this.props.list('all');
    this.props.userBasedsubscriptions();
  }

  render() {
    if(this.props.subscriptions.body == [] || !this.props.subscriptions){
      this.props.username && this.props.userBasedsubscriptions();
    }
    return (
      <Course courses={this.props.courses} subscriptions={this.props.subscriptions} FecthCourseContent={this.props.FecthCourseContent}/>
    )
  }
}

const mapStateToProps = state => {
    return { courses: state.courses,subscriptions: state.subscriptions.body,
      username: state.user.user.username,};
};
export default connect(mapStateToProps,{FecthCourseContent,userBasedsubscriptions,list})(CourseWrap);
 