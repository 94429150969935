import {
  LIST,
  VIEW,
  DETAIL,
  ADDTOCART,
  PROD_QUANTITY,
  GET_CATEGORIES,
  SUBSCRIBE_COURSE,
  CATEGORY_BASED,
  COURSE_CONTENT,
} from "./course.types";
import _ from "lodash";
import { SORT_MY_COURSES } from "../subscription/subscription.types";
import { success, error } from "../../components/Alerts";
const INITIAL_STATE = {};

const courseReducers = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case LIST:
      return { ..._.mapKeys(action.payload, "id") };
    case CATEGORY_BASED:
      return { ..._.mapKeys(action.payload, "id") };
    case VIEW:
      return { [action.payload.id]: action.payload };
    case COURSE_CONTENT:
      return { [action.payload.id]: action.payload };
    case SORT_MY_COURSES:
      if (action.payload.status !== 200) {
        return {};
      }
      var courseids = [];
      action.payload.body.map((course) => {
        courseids.push(course.course_id);
      });
      var result = _.pick(state, courseids);

      return { ...result };
    default:
      return state;
  }
};
export const courseDetailReducers = (state = "", action) => {
  switch (action.type) {
    case DETAIL:
      return { ...state, detail: action.payload };
    default:
      return state;
  }
};
export const subscriptionStatus = (state = "", action) => {
  switch (action.type) {
    case SUBSCRIBE_COURSE:
      if (action.payload === null) {
        error("failed to subscribe ");
        return { subscribed: false };
      } else if (action.payload.status !== 200) {
        error("failed to subscribe ");
        return { subscribed: false };
      } else if (action.payload.status === 200) {
        success("course subscribed successfully ");
        return { subscribed: true };
      }
    default:
      return state;
  }
};
export const MyCartReducer = (state = "", action) => {
  const id = action.id;
  const idExists = Object.values(state).filter((course) => course.id === id);
  switch (action.type) {
    case ADDTOCART:
      if (idExists.length > 0) {
        var newState = Object.values(state).filter(
          (course) => course.id !== id
        );
        return newState;
      }
      return { ...state, [id]: action.payload };
    case SUBSCRIBE_COURSE:
      if (idExists.length > 0) {
        var newState = Object.values(state).filter(
          (course) => course.id !== id
        );
        return newState;
      }
  
    default:
      return state;
  }
};

export const categoryReducer = (state = "", action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default courseReducers;
