
import { Col, Menu, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
const footerStyles = {
  companyAddress: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#717171",
  },
  tradeMark: {
    fontFamily: "'Source Sans Pro', sans-serif",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    color: "#717171",
    padding: "1rem 0",
  },
  menuItem: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    color: "#343434",
    fontStyle: "normal",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
};
const footer = () => {
  return (
   
    <footer
      className="footer"
      // style={{ backgroundColor: "#FFFFFF", border: "0", borderTop: "1px solid rgb(240, 240, 240)" }}
    >
      <div className="footer-container">
        <Row style={{ alignItems: "center" }}>
          <Col span={6}>
            <Row>
              <Col
                span={24}
                style={{ display: "flex", columnGap: "1rem", alignItems: "center", paddingBottom: "1.5rem" }}
              >
                <Link to="/" className="companyLogo">
                  <div style={{ width: "41px", height: "41px" }}>
                    <img
                      src={process.env.REACT_APP_MEDIA_BASE_URL+"/assets/LMS_Imgs/company-logo.png"}
                      alt="Company logo"
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <p>EASY LEARN</p>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <p style={footerStyles.companyAddress}>1151 Pittsford Victor Rd #200 New York(NY), 14534</p>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
          <Col span={16}>
            <Menu style={{ display: "flex", border: "none", justifyContent: "right", marginBottom: "20px" }}>
              <Menu.Item key="1">
                <Link to="/courses" style={footerStyles.menuItem}>
                  COURSES
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/about" style={footerStyles.menuItem}>
                  ABOUT
                </Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/privacy-policy" style={footerStyles.menuItem}>
                  PRIVACY POLICY
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/terms-conditions" style={footerStyles.menuItem}>
                  TERMS & CONDITION
                </Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/contact-us" style={footerStyles.menuItem}>
                  CONTACT
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </div>
      <hr style={{ borderTop: "1px solid #F0F0F0", borderBottom: "0" }} />
      <p classname="trademark" style={footerStyles.tradeMark}>Easy Learn &copy;2022 Created by SJ Innovation</p>
    </footer>
  );
};

export default footer;
