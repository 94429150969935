import React ,{useState} from 'react'
import { Avatar, List , Card, Row, Col, Meta, Image} from 'antd';
import { LeftOutlined, UserOutlined, DownOutlined  } from '@ant-design/icons';
import moment from 'moment';
import { getUserData } from '../../store/user/user.actions';
import "./QueryBoard.css"


const RepliesAdded = (props) => {
  const [open, setOpen] = useState(false)
    let qid
    const setMode= () => {
      open ? setOpen(false) : setOpen(true)
    }
    const cognitoUser = getUserData(props.query[props.id].user_id)
   
  return (
    <div>
       
        <Row>
            <Col span={1}>
            <Avatar size="small" icon={<UserOutlined />} />
        </Col>
        {console.log(props.query[props.id])}
        <Col span={23}>
        <div className='queryboard-head'>
        <Card title={props.query[props.id].title} bordered={false}>
          <span className='user-of-query'><p className='moment'>
            {props.query[props.id].user_id} .  {props.query[props.id].creation_date ? moment(props.query[props.id].creation_date).fromNow() : "Recently Posted"}</p>
            </span>

          <p className='query-description'>{props.query[props.id].description}</p>
          {props.query[props.id].attachment ?  
          <Image 
          width={400}
          src={props.query[props.id].attachment}
          />
          : null}
          { props.query[props.id].query_reply ? 
          <div >
      
          <p className='query-replies' onClick={setMode}>{props.query[props.id].query_reply.length} Replies <DownOutlined /></p> 
      
    {open ? props.query[props.id].query_reply.map( reply => (
    <div className='replied'>
    <div className='replied-avatar'>
      <Avatar size="small" icon={<UserOutlined />} />
      </div>
      <div className='replied-username'>
    <Card title={reply.user_id + " . "+moment(reply.creation_date).fromNow()} bordered={false}>
      
    <div className='replied-description'>
    <p>{reply.description}</p> 
    {reply.attachment ? 
    <Image 
    width={200}
    src={reply.attachment}
    />
  :null}
    </div>
    </Card>
    
    </div>
    </div>
          
          )
        )
        : null }
      
          </div>
          : null}
        </Card>
        </div>
        </Col>
        </Row>
    </div>
  )
}

export default RepliesAdded