import { SUBMITTED  } from "./oneonone.types";
import {CLIENT_BASE_URL_AXIOS} from '../../services/apis';




export const submitOneonone = (values,courseid) => async (dispatch,getState) => {
    var date = new Date(values.request_date)
    date.setSeconds(0,0)
    var final_date = date.toISOString().replace(/:00.000Z/, "");
    var response;
    const body =JSON.stringify(
        {
            course_id:courseid,
            user_email: getState().user.user.email,
            reason:values.query,
            creation_date:final_date
      });
    
        response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_ONEONONE_REQUEST_ENDPOINT,body);
    dispatch({ type: SUBMITTED, payload: SUBMITTED });
};