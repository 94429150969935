import React, { useEffect, useState } from "react";

function VideoPlayer({ video, subscribed, this_video_chapter }) {
  const [videosrc, setVideosrc] = useState(video.video_url);
  useEffect(() => {
    setVideosrc(video.video_url);
  }, [video]);

  const rendervideo = () => {
    if (
      (subscribed.length == 0 && this_video_chapter.sort_order !== 1) ||
      (subscribed.length == 0 && this_video_chapter.sort_order == 1 && video.sort_order !== 1)
    ) {
      return (
        <div>
          <h1> course not subscribed , please subscribe the course to view other videos</h1>
        </div>
      );
    } else {
      return (
        <video key={videosrc} width="100%" controls loop autoPlay>
          <source src={videosrc} type="video/mp4" />
        </video>
      );
    }
  };
  return <>{rendervideo()}</>;
}

export default VideoPlayer;
