import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form } from "antd";
import createField from "../../components/Form/createField";
import Notifications from "../../components/Notifications";
import { RESET_PASSWORD } from "../../store/user/user.types";

class ResetForm extends React.Component {
  onSubmit = (values) => {
    this.props.onSubmit(values);
  };

  render() {
    const { user } = this.props;
    return (
      <>
        {user.action === RESET_PASSWORD ? <Notifications user={user} /> : null}
        <Form onFinish={this.props.handleSubmit(this.onSubmit)}>
          <Field name="code" placeholder="Confirmation Code" component={createField} />
          <Field name="password" placeholder="New Password" component={createField} />
          <Field name="confirmPassword" placeholder="Confirm New Password" component={createField} />
          <div className="btn-container">
            <Button type="primary" htmlType="submit" className="btn">
              Submit
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = "This field is Required";
  }
  if (!values.email) {
    errors.email = "This field is Required";
  }
  if (!values.password) {
    errors.password = "This field is Required";
  }
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

export default reduxForm({
  form: "ResetForm",
  validate,
})(ResetForm);
