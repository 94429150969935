import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import RegistrationForm from "./RegistrationForm";
import { createUser, verifyUser } from "../../store/user/user.actions";
import { CREATE_USER, VERIFY_USER } from "../../store/user/user.types";
import "./Register.css";
class Register extends React.Component {
  onSubmit = (formValues) => {
    this.props.createUser(formValues);
  };
  verifyUser = (code) => {
    this.props.verifyUser(code);
  };
  render() {
    return (
      <Layout>
        <Content>
          <div className="signupPage">
            <div className="signupWrapper">
              <div className="container">
                <h3>
                  {(this.props.user.action === CREATE_USER && this.props.user.user.status) ||
                  (this.props.user.action === VERIFY_USER && !this.props.user.user.status) ? (
                    <span className="verificationCode">Enter Verification Code</span>
                  ) : (
                    <span>Sign up</span>
                  )}
                </h3>
                <RegistrationForm onSubmit={this.onSubmit} verify={this.verifyUser} user={this.props.user} />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps, { createUser, verifyUser })(Register);
