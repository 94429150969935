import React from "react";
import VideoList from "./VideoList";
import Menu from "antd/lib/menu";
function Chapters({ course, onVideoSelect,selectedVideo }) {
  const renderChapters = () => {
    return course.chapter_data.map((chapter) => {
      var videolist = chapter.chapter_content_data;
      return (
        <>
          <Menu.SubMenu title={chapter.name}>
            <VideoList selectedVideo={selectedVideo}
              onVideoSelect={onVideoSelect}
              key={chapter.id}
              videos={videolist}
            />
          </Menu.SubMenu>
        </>
      );
    });
  };
  return (
    <>
      <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        {renderChapters()}
      </Menu>
    </>
  );
}

export default Chapters;
