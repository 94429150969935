import './App.css';
import 'antd/dist/antd.min.css';
import { Route,Routes } from 'react-router-dom';
import routes from './constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateRoute, GuestRoute} from './services/PrivateRoute';
import { currentUser } from './store/user/user.actions';
import history from './services/History';
import CustomRouter from './services/CustomRouter';

const App = () => {
  const dispatch = useDispatch();
  const { session,isSignedIn } = useSelector(state => state.user);

  if(!session) {
    dispatch(currentUser());
  }

  return <div className="App">
    <CustomRouter history={history}>
      <Routes>
        {routes.map((route, key) => {
          var element = route.element;

          if(route.isSignedIn) {
            element = <PrivateRoute isSignedIn={isSignedIn}>
              {route.element}
            </PrivateRoute>
          } else if (!route.isSignedIn && route.isSignedIn !== null) {
            element = <GuestRoute isSignedIn={isSignedIn}>
              {route.element}
            </GuestRoute>
          }
          return (
            <Route path={route.path} element={element} key={key}/>
          );
        })}
      </Routes>
    </CustomRouter>
  </div>
};

export default App;
