import { LIST, DETAIL,ADDTOCART,PROD_QUANTITY, SUBSCRIBE_COURSE, GET_CATEGORIES, COURSE_CONTENT} from './course.types';
import {CLIENT_BASE_URL_AXIOS} from '../../services/apis';
import _ from 'lodash';
import { Loading } from '../../components/Alerts';
import { userBasedsubscriptions } from '../subscription/subscription.actions';
const courseAPIUrl = process.env.REACT_APP_COURSES_LIST;

export const list = (limit) => async (dispatch,getState) => {
    var response;
   
        response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_COURSES_LIST+'?slug='+limit);
    await dispatch({ type: LIST, payload: response.data.body });
    _.chain(getState().courses)
    .map('category_id')
    .uniq()
    .forEach(id => dispatch(getCategories(id)))
    .value();
};

export const categoryBased = (limit,categorySlug) => async (dispatch) => {
    var response;
    response = await CLIENT_BASE_URL_AXIOS.get(courseAPIUrl+'?slug='+categorySlug);
    
};
export const getCategories = (id) => async (dispatch,getState) => {
    var response;
   
        response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_CATEGORY_ENDPOINT+'/listing?id='+id);
    dispatch({ type: GET_CATEGORIES, payload: response.data.body });
};


export const DetailView = (id) => async (dispatch,getState) => {
    var response;
    response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_SINGLE_COURSE+'?slug='+id);
    dispatch({ type: DETAIL, payload: response.data.body[0] });
};

export const FecthCourseContent = (id) => async (dispatch,getState) => {
    var response;
    response = await CLIENT_BASE_URL_AXIOS.get(process.env.REACT_APP_COURSE_CONTENT+'/'+id);
    
    dispatch({ type: COURSE_CONTENT, payload: response.data });
};

export const AddToCart = (course) => async(dispatch,getState) =>{
    var Defaultcourse={...course,quantity:1}
   dispatch( { type: ADDTOCART, payload: Defaultcourse , id:course.id });
};

export const ChangeQuantity = (number,id) => async(dispatch,getState) =>{
   dispatch( { type: PROD_QUANTITY, payload: number , id:id });
};


export const SubscribeCourse = (course,token) => async(dispatch,getState) =>{
    if(course===null){
       return dispatch( { type: SUBSCRIBE_COURSE, payload: null });
    }
    const body =JSON.stringify({
        "user_id": getState().user.user.username,
        "course_id":course.id,
        "amount":course.price,
        "token":token.id
      });
   Loading('Processing..')
   const response = await CLIENT_BASE_URL_AXIOS.post(process.env.REACT_APP_SUBSCRIPTION_PLACED, 
   body
 ,{
   headers: {
   'Content-Type': 'application/json',
   'cognito-token': getState().user.user.token,
   'x-api-key':'Q23so1jMLq1iYFnb0X1kN9BuYWstcka74PHBudXp'
   }
 }
 );
   dispatch( { type: SUBSCRIBE_COURSE, payload: response, id : course.id });
   dispatch(userBasedsubscriptions())
};


