import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Badge, Card, Pagination } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { AddToCart } from "../../store/course/course.actions";
import { Link } from "react-router-dom";
import { ChangeQuantity } from "../../store/course/course.actions";
import { NO_IMAGE } from "../../constants/DefaultLinks";
import { Avatar, List } from 'antd';
class VerticalLayout extends Component {
  render() {
    if (!this.props.ListData[0] == undefined) {
      return null;
    }
    return (
      <>
        <div className="wrapper">
      
          <div className="gap">
          
            <Row gutter={[0, 24]}>
            
              {this.props.ListData.map((data) => {
                return (
                  // <Col span="24" className="view_main"> 
                 
                  <Col span={12} offset={6} className="view_main"> 
                  <Card hoverable >
                    <Row gutter={[24, 15]} align="middle" justify="center">
                      <Col sm={24} xl={6}>
                        <Badge.Ribbon text={`new`} color="volcano">
                          <img
                            alt="data image"
                            src={`${
                              data.thumbnail ? data.thumbnail : NO_IMAGE
                            }`}
                            width="70%"
                            height = "70%"
                          />
                        </Badge.Ribbon>
                      </Col>
                      <Col sm={24} xl={18}>
                        <Row justify="center" align="middle">
                          <Col span="20">
                            <Link to={`${this.props.LinkPath}/${data.id}`}>
                              <p className="title">{data.name}</p>
                            </Link>
                          </Col>
                          <Col span="4">
                            <div className="btn text-align-center">
                              <Button
                                style={{ width: "50%", border: "none" }}
                                size="large"
                              >
                              {/* <EyeOutlined size={"large"} />  */}
                              </Button>
                            </div>
                          </Col>
                          <Col span="24">
                            <p className="content">
                              {data.description.substring(0, 80)}
                              <Link to={`${this.props.LinkPath}/${data.id}`}>
                                ....
                              </Link>{" "}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    </Card>
                  </Col>
                );
              })}
              
            </Row>
            
          </div>
          <div
            className="links"
            style={{
              position: "relative",
              float: "right",
              background: "none",
            }}
          >
            <Row>
              <Col>
              </Col> 
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, { AddToCart, ChangeQuantity })(
    VerticalLayout
);
