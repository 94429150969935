import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import courseReducers, { categoryReducer, MyCartReducer, subscriptionStatus } from './course/course.reducers';
import userReducers from './user/user.reducers';
import { courseDetailReducers } from './course/course.reducers';
import subscriptionReducers from './subscription/subscription.reducers';
import { webinarReducers } from './webinar/webinar.reducers';
import { webinarRegister } from './webinar/webinar.reducers';
import { oneononeReducers } from './oneonone/oneonone.reducers';
import { queryboardReducers, queryReducers, imageUploadedReducers } from './queryboard/queryboard.reducers';
import courseCreatorReducers from './coursecreator/coursecreators.reducers';

export default combineReducers({
    form: formReducer,
    user: userReducers,
    courses: courseReducers,
    detail: courseDetailReducers,
    mycart: MyCartReducer,
    categories: categoryReducer,
    subscriptionstatus: subscriptionStatus,
    subscriptions: subscriptionReducers,
    webinars: webinarReducers,
    webRegStatus: webinarRegister,
    OneOnOne: oneononeReducers,
    queryboard: queryboardReducers,
    query: queryReducers,
    image: imageUploadedReducers,
    cc_user: courseCreatorReducers
});