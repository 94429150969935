import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import LoginForm from "./LoginForm";
import { loginUser, sendCode, verifyUser } from "../../store/user/user.actions";
import createField from "../../components/Form/createField";
import { Button } from "antd";
import { LOGIN_USER_FAILED } from "../../store/user/user.types";
import "./Login.css";
class Login extends React.Component {
  onSubmit = (formValues) => {
    this.props.loginUser(formValues);
  };
  verifyUser = (code) => {
    this.props.verifyUser(code);
  };
  render() {
    const { user } = this.props;
    return (
      <Layout>
        <Content>
          <div className="loginPage">
            <div className="loginWrapper">
              <div className="container">
                <h3>Login</h3>
                <LoginForm onSubmit={this.onSubmit} user={user} verify={this.verifyUser} />
                {user.action === LOGIN_USER_FAILED && user.user.msg === "User is not confirmed." ? (
                  <Button classname="primaryBtn" type="primary" onClick={() => this.props.sendCode()}>
                    Verify Account
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
      
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps, { loginUser, sendCode, verifyUser })(Login);
