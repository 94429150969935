import React, { useState } from "react";
import { Button, Form, Input, Radio, Row, Col, TextArea } from "antd";
import "./ContactUs.css";
import Layout from "../../components/Layout";
import { Content } from "antd/lib/layout/layout";

const ContactUs = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  return (
    <Layout>
      <Content>
        <div className="contact-us">
          <h3>Contact Us</h3>
          <h6>Need help?</h6>
          <p>
            If you’re a learner on the Easy Learn platform and have questions or need technical assistance, please write
            down to us. Our support team will reach out to you on the concerned query.
          </p>

          <Form className="contact-us-form">
            <Row>
              <Col span={8}>
                <Form.Item label="Full Name">
                  <Input placeholder="Your Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Email">
                  <Input placeholder="Your Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="Message">
                  <TextArea rows={4} placeholder="Your Message" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary">SEND</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default ContactUs;
